import { getFormattedDate } from '../../../../../../utils/dateUtils';
import { P6_XML_DATE_FORMAT } from '../constants';

/**
 * Formats a given date to the specified P6 XML format.
 *
 * @param {string|Date} date - The date to be formatted. It can be a string or a Date object.
 * @returns {string} The formatted date string in the P6 XML format.
 */
export const getFormattedDateToP6XML = (date) => {
  return getFormattedDate({
    customFormat: P6_XML_DATE_FORMAT,
    date
  });
};
