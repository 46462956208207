import {
  LOOKAHEAD,
  MESSAGE_FIT_ONE_PAGE,
  NEW_TAB,
  SCHEDULE
} from '../constants';
import { getHeaderPDF } from '../Templates/header';
import { getFooterPDF } from '../Templates/footer';
import { getCSSPDF } from '../style/index';
import { formatIsAllContent, getTotalPages } from './configPages';
import { getZoomLevel } from './updateGantt';
import { getFormattedPdfDate } from './formateDate';
import { base } from '../../../../../../services/base';
import { sanitizeForFilename } from '../utils/export.utils';

const exportableServerURL = base.exportableUrl;

/**
 * Create a PDF file to export with the gantt.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @param {Function} t - Translation function.
 * @param {string} lang - Language code.
 * @param {Object} pageDimensions - Dimensions of the pages for export.
 * @param {boolean} isLookahead - Flag indicating if the export is for lookahead.
 * @param {Object} config - Configuration settings for the export.
 * @param {boolean} config.isFitOnePage - Flag indicating if the content should fit one page.
 * @param {boolean} config.isTrimParent - Flag indicating if parent tasks should be trimmed.
 * @param {boolean} config.showOwnerLogo - Flag indicating if owner logo should be added.
 * @param {boolean} config.showOrganizationLogo - Flag indicating if organization logo should be added.
 * @param {boolean} config.showFooterLegend - Flag indicating if footer legend should be added.
 * @param {Date} [config.newDate=new Date()] - The date for the export.
 * @param {Date} [config.newDateToday=new Date()] - The current date for the export.
 * @param {string} config.formatToExport - Format to export.
 * @returns {Promise[]} - An array of promises, each resolving to a PDF file for the Gantt chart.
 */
export const createFileExportToPDF = ({
  gantt,
  t,
  lang,
  pageDimensions,
  isLookahead,
  config
}) => {
  const {
    title,
    subtitle,
    isFitOnePage,
    isTrimParent,
    formatToExport,
    printedOn,
    alignLineDate,
    showOwnerLogo,
    showOrganizationLogo,
    showFooterLegend
  } = config;

  const totalPages = getTotalPages({
    gantt,
    isFitOnePage,
    pageDimensions,
    isTrimParent
  });
  const isAllContent = formatIsAllContent(formatToExport);
  const zoomLevel = getZoomLevel(gantt);

  const headerPDF = getHeaderPDF({
    title,
    subtitle,
    showOwnerLogo,
    showOrganizationLogo
  });
  const footerPDF = getFooterPDF({
    gantt,
    t,
    isSchedule: !isLookahead,
    pageOrientation: pageDimensions.pageOrientation,
    date: printedOn,
    showLegend: showFooterLegend
  });

  const currentPageDimensions = {
    ...pageDimensions,
    customProplanner: {
      totalPages,
      format_text: formatToExport,
      level: zoomLevel,
      lang,
      isFitOnePage,
      isTrimParent
    }
  };

  const cssPDF = getCSSPDF({
    gantt,
    isLookahead,
    isAllContent,
    pageDimensions
  });

  return gantt.exportToPDF({
    raw: false,
    skip_circular_links: true,
    header: headerPDF + cssPDF,
    footer: footerPDF,
    server: exportableServerURL,
    additional_settings: currentPageDimensions,
    newTodayLine: alignLineDate,
    newDateToday: alignLineDate
  });
};

/**
 * Opens a PDF in a new browser tab.
 *
 * @param {string} url - The URL of the exported PDF.
 */
const openPDFInNewTab = (url) => {
  window.open(url, NEW_TAB);
};

/**
 * Handles the result of the PDF export.
 * If a URL is provided, it opens in a new tab.
 * If there is a message, it opens a modal that fits the content to one page.
 *
 * @param {Object} params - Function parameters.
 * @param {Object} params.exportResult - Result of the export.
 * @param {string} [params.exportResult.url] - URL of the exported PDF.
 * @param {string} [params.exportResult.message] - Error message from the service if no URL is available.
 * @param {Object} params.gantt - The Gantt instance.
 */
export const handleEventResultExportPDF = ({
  exportResult,
  gantt,
  openFitToOnePageModal
}) => {
  const { url, message } = exportResult;

  if (url) {
    openPDFInNewTab(url);
    return;
  }

  if (message === MESSAGE_FIT_ONE_PAGE) {
    openFitToOnePageModal(gantt);
  }
};

/**
 * Generates the name for the exported PDF file.
 *
 * @param {Object} params - The parameters for generating the PDF name.
 * @param {boolean} isSchedule - Indicates if the export is for a schedule or lookahead.
 * @param {string} projectName - The name of the project.
 * @param {string} sectorName - The name of the sector.
 * @param {string} formatToExport - The format of the export.
 * @returns {string} - The generated PDF file name.
 */
export const getExportPDFName = ({
  isSchedule,
  projectName,
  sectorName,
  formatToExport
}) => {
  const formatDate = getFormattedPdfDate();
  const ganttType = isSchedule ? SCHEDULE : LOOKAHEAD;
  const pdfName = `${ganttType}_exportable_${projectName}_${sectorName}_${formatDate}_${formatToExport}`;
  const sanitizedPdfName = sanitizeForFilename(pdfName);
  return `${sanitizedPdfName}.pdf`;
};
