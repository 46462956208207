import { createGanttElementIdMapping } from '../helpers/createGanttElementIdMapping';
import { getLinksToExportP6 } from './links';
import { getSectorToExportP6 } from './project';
import { getTasksToExportP6 } from './tasks';

/**
 * Retrieves the necessary resources for exporting a project to P6 format, including calendars, tasks, links, and project data.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The gantt data used for mapping and task/links extraction.
 * @param {Object} params.projectState - The current project state, providing context for calendars and tasks.
 * @returns {Promise<Object>} A promise that resolves to an object containing the calendars, links, tasks, and project data for P6 export.
 */
export const GetResourcesForP6Export = ({
  getCurrentProjectState,
  getCurrentSectorState
}) => {
  const execute = async (dto) => {
    try {
      const { gantt, projectState } = dto;

      const elementIdMapping = createGanttElementIdMapping(gantt);

      const currentProject = await getCurrentProjectState(projectState);
      const currentSector = await getCurrentSectorState(projectState);

      const sector = await getSectorToExportP6({
        gantt,
        currentProject,
        currentSector
      });

      const tasks = getTasksToExportP6({
        gantt,
        elementIdMapping
      });
      const links = await getLinksToExportP6({
        gantt,
        currentSector,
        elementIdMapping
      });

      return {
        links,
        tasks,
        sector
      };
    } catch (error) {
      throw new Error(`Failed in GetResourcesForP6Export: ${error.message}`, {
        cause: error
      });
    }
  };

  return { execute };
};
