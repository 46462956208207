import moment from 'moment';
import { registerTrackingEvent } from './copy';
import { launchMessage } from '../message.api';
import {
  enterEvent,
  formatsDates,
  formatsDuration,
  noPasteColumns,
  noPasteColumnsLookaHead,
  validFormatRegexCustoms
} from '../constants';

export function isRowEditable(element) {
  if (element.tagName === 'INPUT') return true;
  const row =
    element.closest('.gantt_row') ||
    element.closest('.gantt_cell')?.closest('.gantt_row');
  if (!row) return;
  return row.classList.contains('activitytask-custom-style');
}
export function getColumnAndTask(gantt, activeElement) {
  let columnName = activeElement.getAttribute('data-column-name');
  let selectedTaskId = gantt.getSelectedId();
  if (!columnName) {
    const editorState = gantt.ext.inlineEditors.getState();
    if (editorState) {
      columnName = editorState.columnName;
      selectedTaskId = editorState.id;
    }
  }
  return { columnName, selectedTaskId };
}

export function isEditableCell(activeElement, columnConfig, columnName) {
  if (isInvalidRow()) return false;
  return (
    activeElement.tagName === 'INPUT' ||
    (columnConfig?.editor && !noPasteColumns.includes(columnName))
  );
}

export function isEditableCellLookahead(
  activeElement,
  columnConfig,
  columnName
) {
  return (
    activeElement.tagName === 'INPUT' ||
    (columnConfig &&
      columnConfig.editor &&
      !noPasteColumnsLookaHead.includes(columnName))
  );
}

export function prepareCellForEditing(
  gantt,
  activeElement,
  selectedTaskId,
  columnName
) {
  if (activeElement.tagName !== 'INPUT' && columnName !== 'constraint_date') {
    gantt.ext.inlineEditors.startEdit(selectedTaskId, columnName);
  }
  if (activeElement.tagName !== 'INPUT' && columnName === 'constraint_date') {
    const rowElement = activeElement.closest('.gantt_row');
    const isRowSelected =
      rowElement && rowElement.classList.contains('gantt_selected');
    if (!isRowSelected) {
      gantt.ext.inlineEditors.startEdit(selectedTaskId, columnName);
      activeElement.click();
    }
  }
}

export function prepareCellForEditingLookahead(
  gantt,
  activeElement,
  selectedTaskId,
  columnName
) {
  if (activeElement.tagName !== 'INPUT') {
    gantt.ext.inlineEditors.startEdit(selectedTaskId, columnName);
    const visual = gantt.ext.inlineEditors;
    if (visual._editorType === 'date') {
      document.querySelector('.schedule-datepicker')?.focus();
    }
  }
}

export function pasteTextIntoCell(copiedText, columnName, t) {
  let inputInsideCell = document.activeElement;
  if (inputInsideCell?.tagName === 'INPUT') {
    inputInsideCell.value = copiedText;
    inputInsideCell.dispatchEvent(enterEvent);
    launchMessage(false, false, true)(t, null, 'success');
    registerTrackingEvent(columnName, 'schedule_cell_pasted', 'SCHEDULE');
  } else {
    launchMessage(false, false, true)(t, null, 'error');
  }
}

export function pasteTextIntoCellLookaHead(copiedText, columnName, t) {
  let inputInsideCell = document.activeElement;
  if (inputInsideCell?.tagName === 'INPUT') {
    inputInsideCell.value = copiedText;
    inputInsideCell.dispatchEvent(enterEvent);
    launchMessage(false, false, true)(t, null, 'success');
    registerTrackingEvent(columnName, 'lookahead_cell_pasted', 'LOOKAHEAD');
  } else {
    launchMessage(false, false, true)(t, null, 'error');
  }
}

export function validateCopiedText(copiedText, columnConfig, t, columnName) {
  if (columnConfig.data_type === 'date') {
    copiedText = validateDateFormat(copiedText, t);
    if (copiedText === null) return null;
  }
  if (columnConfig.data_type === 'number' && columnConfig.name !== 'duration') {
    copiedText = validateNumberFormat(copiedText, t);
    if (copiedText === null) return null;
  }
  if (columnConfig.name === 'duration') {
    copiedText = validateFormatDuration(copiedText, t);
  }
  if (['custom_predecessors', 'custom_sucessors'].includes(columnName)) {
    copiedText = validatePredecessorSuccessorFormat(copiedText, t);
    if (copiedText === null) return null;
  }
  return copiedText;
}

function validateDateFormat(copiedText, t) {
  const copiedDatePart = copiedText.split(' ')[0];
  const parsedDate = moment(copiedDatePart, formatsDates, true);
  if (!parsedDate.isValid()) {
    launchMessage(false, false, true)(t, null, 'noDate');
    return null;
  }
  return copiedText;
}

function validateNumberFormat(copiedText, t) {
  copiedText = copiedText.trim();
  let numericValue = Number(copiedText);
  if (isNaN(numericValue)) {
    launchMessage(false, false, true)(t, null, 'noNumber');
    return null;
  }
  return numericValue;
}

function validateFormatDuration(copiedText, t) {
  const copiedTextValidation = copiedText.trim();
  const parts = copiedTextValidation.split(' ');
  if (parts.length === 1 && !isNaN(parts[0])) {
    return copiedText;
  }
  const lastWord = parts[parts.length - 1];
  if (!formatsDuration.includes(lastWord)) {
    launchMessage(false, false, true)(t, null, 'noNumber');
    return null;
  }
  return copiedText;
}

function validatePredecessorSuccessorFormat(copiedText, t) {
  if (isInvalidRow()) {
    launchMessage(false, false, true)(t, null, 'noPasteInFather');
    return null;
  }

  if (!isValidFormat(copiedText)) {
    launchMessage(false, false, true)(t, null, 'noNumber');
    return null;
  }

  return copiedText.trim();
}

function isInvalidRow() {
  const activeElement = document.activeElement;
  const rowElement = activeElement?.closest('.gantt_row');
  return rowElement?.classList.contains('gantt_row_project');
}

function isValidFormat(copiedText) {
  copiedText = copiedText.trim();
  return (
    Number.isInteger(Number(copiedText)) ||
    validFormatRegexCustoms.test(copiedText)
  );
}
