/**
 * Retrieves the Gantt chart instance based on the schedule setting.
 *
 * @param {boolean} isSchedule - Indicates whether to return the schedule Gantt chart instance.
 * @returns {Object} The Gantt chart instance for either schedule or lookahead based on the input.
 */
export const getGanttToExport = (isSchedule = true) => {
  const {
    to_use_react_gantt: ganttSchedule,
    ganttVisualization: ganttLookahead
  } = window;

  if (isSchedule) {
    return ganttSchedule;
  }

  return ganttLookahead;
};
