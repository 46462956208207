import { activityPdfService } from '../../../../../../services';
import {
  getCurrentProjectState,
  getCurrentSectorState,
  getSignedUser
} from '../../../../../../utils/userUtils';
import { getPageDimensions, langExportPDF } from './configPages';
import { createFileExportToPDF, getExportPDFName } from './exportPDF';
import {
  calculateElapsedTimeInSeconds,
  calculateRemainingTimeout
} from './formateDate';
import { showExportProcessingMessage } from './notifyUser';
import {
  setupGanttAfterPDFGeneration,
  setupGanttBeforePDFGeneration
} from './updateGantt';

/**
 * Generates and exports the Gantt chart as a PDF file.
 *
 * @param {Object} gantt - The Gantt chart instance to be exported.
 * @param {Function} t - Translation function for localization.
 * @param {Object} config - Configuration settings for the PDF export.
 * @param {string} config.formatToExport - The format of the export (e.g., all content, visible content).
 * @param {string} config.pageOrientation - The orientation of the PDF page (portrait or landscape).
 * @param {boolean} isSchedule - Determines if the export is for a schedule or lookahead.
 *
 * @returns {Promise<Object>} A promise that resolves with the response from the PDF export service.
 */
export const createPDFExport = async ({
  gantt,
  t,
  projectState,
  config,
  isSchedule,
  exportStartTime,
  handleCloseModal
}) => {
  const exportProcessingMessage = ({
    isScheduled,
    t,
    exportStartTime,
    handleCloseModal
  }) => {
    const secondsElapsed = calculateElapsedTimeInSeconds(exportStartTime);
    const remainingTimeout = calculateRemainingTimeout(secondsElapsed);

    setTimeout(() => {
      if (loadingPdf) {
        handleCloseModal();
        showExportProcessingMessage({ isScheduled, t });
        setupGanttAfterPDFGeneration({
          gantt,
          isSchedule
        });
      }
    }, remainingTimeout * 1000);
  };

  let loadingPdf = true;
  const lang = langExportPDF();
  const {
    formatToExport,
    pageOrientation,
    isTrimParent,
    makeAvailableForIpad
  } = config;

  setupGanttBeforePDFGeneration({ gantt, isSchedule });
  const pageDimensions = getPageDimensions({
    gantt,
    formatToExport,
    pageOrientation,
    isTrimParent
  });

  const fileExportToPDF = createFileExportToPDF({
    gantt,
    t,
    lang,
    pageDimensions,
    isLookahead: !isSchedule,
    config
  });

  const currentUser = getSignedUser();
  const jsonData = JSON.stringify(fileExportToPDF);

  const { id: projectId, name: projectName } =
    getCurrentProjectState(projectState);
  const { id: sectorId, name: sectorName } =
    getCurrentSectorState(projectState);

  const exportName = getExportPDFName({
    gantt,
    isSchedule,
    projectName,
    sectorName,
    formatToExport
  });

  const exportPDFData = {
    data: jsonData,
    name: exportName,
    current_user: currentUser,
    ipadAvailable: makeAvailableForIpad,
    lang,
    project_id: projectId,
    project: projectName,
    sector_id: sectorId,
    sector: sectorName,
    is_schedule: isSchedule
  };

  exportProcessingMessage({ isSchedule, t, exportStartTime, handleCloseModal });

  const response = await activityPdfService.exportToPDF(exportPDFData);
  setupGanttAfterPDFGeneration({
    gantt,
    isSchedule
  });

  loadingPdf = false;
  return { ...response, exportName };
};
