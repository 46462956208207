/**
 * Retrieves the project task from the gantt.
 *
 * @param {Object} gantt - The gantt object containing tasks.
 * @returns {Object} The project task, identified by the top-level task in the gantt.
 */
export const getProjectTask = (gantt) => {
  const tasks = gantt.getTaskByTime();
  const projectTask = tasks.find(({ $level }) => $level === 0);
  return projectTask;
};
