import { GetResourcesForP6Export } from './GetResourcesForP6Export';
import {
  getCurrentProjectState,
  getCurrentSectorState
} from '../../../../../../utils/userUtils.js';

const getResourcesForP6Export = GetResourcesForP6Export({
  getCurrentProjectState,
  getCurrentSectorState
});

export { getResourcesForP6Export };
