function captureOriginalMetadata(parent) {
  if (parent && parent.type !== 'project') {
    parent.originalMetadata = {
      duration: parent.duration,
      nonParsedDuration: parent.non_parsed_original_duration,
      progress: parent.progress,
      type: parent.type,
      startDate: parent.start_date,
      endDate: parent.end_date
    };
  }
}

export { captureOriginalMetadata };
