import { trackingEventNetworkBlocking } from './trackingEvent';
import notificationSystemV2 from '../../../../../../components/DesignSystem/NotificationSystemV2/NotificationSystemV2';
import { sentryLogger } from '../../../../../../monitor/monitor';

const SUCCESS_TYPE = 'success';
const ERROR_TYPE = 'error';
const TOAST_KEY = 'exportable-sent-by-mail';

/**
 * Shows a network blocking alert and tracks the event.
 *
 * @param {Object} t - Translation function.
 */
export const showNetworkBlockAlert = ({ t }) => {
  try {
    const message = t('export_p6_xml.network_blocking_alert');
    notificationSystemV2({
      key: 'network-blocking-alert',
      type: ERROR_TYPE,
      message
    });
    trackingEventNetworkBlocking({ t });
  } catch (error) {
    sentryLogger.error('showNetworkBlockAlertInP6XML', {
      error
    });
  }
};

/**
 * Shows a notification based on the export result and tracks the event.
 *
 * @param {Function} t - Translation function.
 * @param {Object} exportResult - The result of the P6 XML export.
 * @returns {string} The event result type.
 */
export const showExportP6XMLAlert = ({ t, status }) => {
  const toastType = () => {
    if (!status) {
      const message = t('export_p6_xml.notification.error');
      const eventResult = 'exportable failed';
      return { message, type: ERROR_TYPE, eventResult };
    }

    const message = t('export_p6_xml.notification.success');
    const eventResult = 'exportable generated';
    return { message, type: SUCCESS_TYPE, eventResult };
  };

  const { message, type, eventResult } = toastType();

  notificationSystemV2({
    key: TOAST_KEY,
    type,
    message
  });

  return eventResult;
};
