import { launchMessage } from './message.api';
import { registerTrackingEvent } from './utils/copy';
import {
  getColumnAndTask,
  isEditableCell,
  pasteTextIntoCell,
  prepareCellForEditing,
  validateCopiedText
} from './utils/paste';

export async function handlerCtrlVCell(gantt, t) {
  if (gantt.config.readonly) return;

  const activeElement = document.activeElement;

  activeElement.click();

  const { columnName, selectedTaskId } = getColumnAndTask(gantt, activeElement);
  if (!columnName) return;

  const columnConfig = gantt.config.columns.find(
    (col) => col.name === columnName
  );

  if (!isEditableCell(activeElement, columnConfig, columnName)) {
    launchMessage(false, false, true)(t, null, 'error');
    return;
  }

  prepareCellForEditing(gantt, activeElement, selectedTaskId, columnName);
  let copiedText = await navigator.clipboard.readText();

  copiedText = validateCopiedText(copiedText, columnConfig, t, columnName);
  if (copiedText === null) return;

  pasteTextIntoCell(copiedText, columnName, t);
  registerTrackingEvent(columnName, 'schedule_cell_pasted', 'SCHEDULE');
}
