import { trackingEvent } from '../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../../analytics/utils';
import {
  copyColumns,
  copyColumnsLookaHead,
  typesTagNameContent,
  typesTagNameInput
} from '../constants';
import { launchMessage as messageAPI } from '../message.api';

export function registerTrackingEvent(columnName, nameAction, component) {
  trackingEvent(
    nameAction,
    {
      ...getBasicAmplitudEventProperties(),
      target_column_name: columnName,
      module: component
    },
    AMPLITUDE_SERVICE
  );
}

export const isInput = (element) => typesTagNameInput.includes(element.tagName);

export const getColumnName = (element) =>
  element.getAttribute('data-column-name');

export const isValidColumn = (column) => copyColumns.includes(column);
export const isValidColumnLookahead = (column) =>
  copyColumnsLookaHead.includes(column);

export const getElementText = (element) =>
  typesTagNameContent.includes(element.tagName)
    ? element.innerText
    : window.getSelection().toString();

export const getElementTextLookaHead = (element, columnName) => {
  if (
    typesTagNameContent.includes(element.tagName) &&
    columnName !== 'taskRoute'
  ) {
    const clonedElement = element.cloneNode(true);
    clonedElement.querySelectorAll('.TooltipHTML').forEach((el) => el.remove());
    const textLines = clonedElement.innerText
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line !== '');
    return [...new Set(textLines)].join(' ');
  }
  if (columnName === 'taskRoute') {
    const tooltipTextElement = element.querySelector('.TooltipHTMLtext p');
    if (tooltipTextElement) return tooltipTextElement.textContent.trim();
    const visibleTextElement = element.querySelector('.TooltipHTMLElement');
    if (visibleTextElement) return visibleTextElement.textContent.trim();
    return element.textContent.trim();
  }
  return window.getSelection().toString();
};

export const convertNumber = (text) => {
  let cleanedText = text.replace(/[^0-9,.-]/g, '');

  if (cleanedText.includes('.') && cleanedText.includes(',')) {
    cleanedText = cleanedText.replace(/,/g, '');
  }
  if (!cleanedText.includes('.') && cleanedText.includes(',')) {
    cleanedText = cleanedText.replace(/,/g, '');
  }
  if (cleanedText.includes('.') && cleanedText.endsWith('00')) {
    cleanedText = cleanedText.replace(/\.00$/, '');
  }
  return cleanedText;
};

export const copyToClipboard = (text) => navigator.clipboard.writeText(text);

export const showMessage = (t, type) => messageAPI(false, true)(t, [], type);

export const extractCopiedText = (element, gantt) => {
  if (isInput(element)) return element.value;

  const columnName = getColumnName(element);
  const columnConfig = gantt.config.columns.find(
    (col) => col.name === columnName
  );
  let text = getElementText(element);

  return columnConfig?.data_type === 'number' &&
    columnConfig.name !== 'duration'
    ? convertNumber(text)
    : text;
};

export const extractCopiedTextLookahead = (element, gantt) => {
  if (isInput(element)) return element.value;

  const columnName = getColumnName(element);
  const columnConfig = gantt.config.columns.find(
    (col) => col.name === columnName
  );
  let text = getElementTextLookaHead(element);

  return columnConfig?.data_type === 'number' &&
    columnConfig.name !== 'duration'
    ? convertNumber(text)
    : text;
};
