import { ALL_CONTENT } from './constants';
import { getZoomLevel } from './helpers/updateGantt';
import { showExportPDFAlert } from './helpers/notifyUser';
import { handleEventResultExportPDF } from './helpers/exportPDF';
import {
  trackingEventExportPDFResult,
  trackingEventUnknown
} from './helpers/trackingEvent';
import * as Sentry from '@sentry/react';
import { createPDFExport } from './helpers/createPDFExport';
import { getGanttToExport } from './utils/gantt.utils';

/**
 * Initiates the process to export the Gantt chart as a PDF file.
 *
 * @param {Object} params - The parameters for the export process.
 * @param {boolean} [params.isSchedule=true] - Indicates if the export is for a schedule (default is true).
 * @param {Function} params.t - Translation function for localization (required).
 * @param {Function} [params.handleCloseModal] - Function to handle closing the modal (optional).
 * @param {Function} [params.openFitToOnePageModal] - Function to handle opening the fit-to-one-page modal (optional).
 * @param {Object} params.projectState - The current state of the project (required).
 * @param {Object} [params.config={}] - Configuration settings for the export (optional).
 * @param {boolean} [params.config.isTrimParent=false] - Whether to trim the parent task in the export (default is false).
 * @param {boolean} [params.config.isFitOnePage=false] - Whether to fit the export to a single page (default is false).
 * @param {string} [params.config.formatToExport=ALL_CONTENT] - The format of the export (default is ALL_CONTENT).
 * @param {boolean} [params.config.isShowTodayLinePDF=true] - Whether to show the "today" line in the PDF (default is true).
 * @param {boolean} [params.config.showFooterLegend=true] - Whether to show the footer legend in the PDF (default is true).
 * @param {boolean} [params.config.showOrganizationLogo=true] - Whether to show the organization logo in the PDF (default is true).
 * @param {boolean} [params.config.showOwnerLogo=true] - Whether to show the organization logo in the PDF (default is true).
 * @param {Date} [params.config.printedOn=new Date()] - The print date for the export (default is current date).
 * @param {Date} [params.config.alignLineDate=new Date()] - The date to align the line for export (default is current date).
 *
 * @returns {Promise<void>} A promise that resolves when the PDF export process is complete.
 *
 * @throws {Error} If an error occurs during the export process.
 */
export const exportPDFGantt = async ({
  isSchedule = true,
  t,
  handleCloseModal = (_) => {},
  openFitToOnePageModal = (_) => {},
  projectState,
  config = {}
}) => {
  try {
    const gantt = getGanttToExport(isSchedule);

    if (!gantt) {
      return;
    }

    const zoomLevel = getZoomLevel(gantt);
    const now = new Date();
    const {
      isTrimParent = false,
      isFitOnePage = false,
      makeAvailableForIpad = false,
      formatToExport = ALL_CONTENT,
      isShowTodayLinePDF = true,
      printedOn = now,
      alignLineDate = now,
      showFooterLegend = true,
      showOrganizationLogo = true,
      showOwnerLogo = true
    } = config;

    const configPage = {
      isFitOnePage,
      isTrimParent,
      formatToExport,
      isShowTodayLinePDF,
      printedOn,
      alignLineDate,
      makeAvailableForIpad,
      showFooterLegend,
      showOrganizationLogo,
      showOwnerLogo
    };

    const exportResult = await createPDFExport({
      gantt,
      t,
      projectState,
      config: configPage,
      isSchedule,
      exportStartTime: now,
      handleCloseModal,
      showFooterLegend,
      showOrganizationLogo,
      showOwnerLogo
    });

    const eventResult = showExportPDFAlert({ isSchedule, t, exportResult });

    handleEventResultExportPDF({ exportResult, gantt, openFitToOnePageModal });
    trackingEventExportPDFResult({
      gantt,
      isSchedule,
      zoomLevel,
      configPage,
      eventResult,
      exportName: exportResult?.exportName
    });
  } catch (error) {
    trackingEventUnknown({ t, isSchedule });
    Sentry.captureException(error);
  }
};
