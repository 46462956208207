import cloneDeep from 'lodash/cloneDeep';
import { getNextUID } from '../../../../views/ganttContainer/gantt/gantt.helper';
import { taskService } from '../../../../services';
import { findDeepGetTask } from '../../../../components/GanttVisualization/GanttVisualization.helper';
import {
  convertPPTask2PluginTask,
  findDeepGetActivityOfTask
} from '../../../../views/lookahead/planification/index.helper';
import { launchMessage as messagingAPI } from './message.api';
import { assignCorrelativeNumbers } from '../../../../components/GanttVisualization/GanttVisualization.helper';
import { trackingEvent } from '../../../../analytics';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { TimerManagerSingleton } from '../../../../utils/timerManager';

export function handlePaste(
  gantt,
  t,
  sector,
  updateExpectedGlobal,
  isMasterPlan
) {
  if (gantt.config.readonly) return;
  const timerManager = TimerManagerSingleton.getInstance();

  const selectedTasksDHTMLX = gantt.getSelectedTasks();
  if (selectedTasksDHTMLX.length !== 1 || !gantt.tasksToCopy) return;

  const launchMessage = messagingAPI(false);

  const selectedTasksProplanner = JSON.parse(gantt.tasksToCopy);
  if (selectedTasksProplanner.selectedTasks.length <= 0) {
    launchMessage(t, selectedTasksProplanner, 'noData');
    return;
  }

  /**
   * This function use batchUpdate from DHTMLX gantt to avoid collapse memory, and launch an iteration to create new pasted tasks
   * @param {*} selectedTasksProplanner OBJECT with selectedTasks and selectedLinks
   * @param {*} parent Parent which is going to be used when creating task
   * @param {*} initialIndexRef Where to start the pasting
   */
  const launchBatchUpdate = async ({
    selectedTasksProplanner,
    parent,
    initialIndexRef = 0,
    showMessage,
    pasteElement,
    currentActivity
  }) => {
    try {
      let indexRef = initialIndexRef;
      const tasksToaddToPlugin = [];

      const newToOldHash = {};
      const oldToNewHash = {};
      const createdTasksRefArray = [];
      const toOpenParents = [];
      const selectedTasks =
        selectedTasksProplanner && selectedTasksProplanner.selectedTasks;
      // For instead will be 100% sync so respect the tree order
      for (let index = 0; index < selectedTasks.length; index++) {
        const activity = selectedTasks[index];
        const addedRef = await pasteElement(activity); // pasteActivity
        tasksToaddToPlugin.push({ addedRef, activity });
      }

      gantt.batchUpdate(() => {
        try {
          tasksToaddToPlugin.forEach(({ addedRef, activity }, index) => {
            gantt.createTask(addedRef);
            newToOldHash[addedRef.id] = activity;
            oldToNewHash[activity.id] = addedRef;
            createdTasksRefArray.push(addedRef);
            /** REMEMBER THAT THIS FUNCTION WORKS WITH PARENT CONTEXT INDEX (SO THEN FIRST CHILD START AS 1) */
            isMasterPlan && gantt.moveTask(addedRef.id, indexRef, parent);
            indexRef++;
          });

          createdTasksRefArray.forEach((createdRef, index) => {
            const originalActivity = newToOldHash[createdRef.id];
            const originalParent =
              originalActivity.parent || originalActivity.parent_id;
            const doesExistInHash = oldToNewHash[originalParent];
            if (doesExistInHash) {
              const oldParent = cloneDeep(createdRef.parent);
              const oldParentGanttRef = gantt.getTask(oldParent);
              createdRef.parent = doesExistInHash.id;
              if (!isMasterPlan) {
                const reactRef = findDeepGetTask(
                  window.activities,
                  'id',
                  createdRef.id
                );
                const parentReactRef = findDeepGetTask(
                  window.activities,
                  'id',
                  createdRef.parent
                );
                const oldParentReactRef = findDeepGetTask(
                  window.activities,
                  'id',
                  oldParent
                );
                const activityReactRef = findDeepGetActivityOfTask(
                  window.activities,
                  'id',
                  createdRef.id
                );

                if (reactRef && parentReactRef) {
                  // Update legacy state
                  parentReactRef[
                    parentReactRef.tasks ? 'tasks' : 'children'
                  ].push(reactRef);
                  reactRef.parent_id = createdRef.parent;
                  taskService.update(reactRef);
                }

                // Cleaning old parent children:
                // ----------------------------
                // If old parent was a activity
                if (oldParentGanttRef && oldParentGanttRef.type === 'main') {
                  const actRef = window.activities.find(
                    (act) => act.id === oldParentGanttRef.proplannerId
                  );
                  actRef.tasks = actRef.tasks.filter(
                    (el) => el.id !== reactRef.id
                  );
                  // if old parent was other task
                } else if (
                  oldParentReactRef &&
                  oldParentGanttRef.type === 'activitytask'
                ) {
                  oldParentReactRef.children =
                    oldParentReactRef.children.filter(
                      (el) => el.id !== reactRef.id
                    );
                  // oldParentReactRef[oldParentReactRef.tasks ? 'tasks' : 'children'] = oldParentReactRef[oldParentReactRef.tasks ? 'tasks' : 'children'].filter(el => el.id !== reactRef.id)
                }
                doesExistInHash.is_parent = true;
                createdRef.isChildTask = true;
                // createdRef.activityReference = activityReactRef
                gantt &&
                  gantt.checkIfTaskIsInsideOfWeekly &&
                  gantt.checkIfTaskIsInsideOfWeekly(createdRef);
              }
              gantt.updateTask(createdRef.id);
              if (!toOpenParents.includes(createdRef.parent)) {
                toOpenParents.push(createdRef.parent);
              }
            }
          });
          handleSuccessPasting(
            createdTasksRefArray.length - 1,
            createdTasksRefArray.length
          );
          toOpenParents.forEach((id, index) => {
            if (gantt.isTaskExists(id)) {
              const parentRef = gantt.getTask(id);
              if (isMasterPlan) {
                parentRef.type = 'project';
              } else if (parentRef.isTask) {
                parentRef.type = 'activitytask';
              } else if (!parentRef.isTask && parentRef.type !== 'milestone') {
                parentRef.type = 'main';
              }
              gantt.open(id);
            }
          });

          if (currentActivity && currentActivity.tasks.length > 0) {
            assignCorrelativeNumbers(currentActivity.tasks);
          }
        } catch (e) {
          console.log('error on batchUpdate');
          console.log(e);
        }
      });

      if (isMasterPlan) {
        updateExpectedGlobal();
      } else {
        const toCopyActivityId = selectedTasksDHTMLX[0];
        const toCopyActivity = gantt.getTask(toCopyActivityId);
        const activityReactRef = findDeepGetActivityOfTask(
          window.activities,
          'id',
          toCopyActivity.id
        );
        const activityContainerID =
          toCopyActivity.type === 'main'
            ? toCopyActivity.id
            : parseInt(activityReactRef?.unique_id);
        if (activityContainerID) updateExpectedGlobal(activityContainerID);
      }

      gantt.changeVisualizationOption && gantt.changeVisualizationOption();

      if (!isMasterPlan || !selectedTasksProplanner.selectedLinks) return;
      const params = {
        newToOldHash,
        oldToNewHash,
        createdTasksRefArray,
        toOpenParents
      };
      pasteLinks(selectedTasksProplanner.selectedLinks, params);
    } catch (e) {
      console.log('Error on launchBatchUpdate function');
      console.log(e);
    }
  };

  const pasteTask = async (task, parent) => {
    if (!task) return;
    const currentSector = sector;
    const parentObj = gantt.getTask(parent);
    const isActivity = parentObj.type === 'main';
    let activityReactRef = findDeepGetActivityOfTask(
      window.activities,
      'id',
      parentObj?.id
    );
    // validate case when parent is actually the activity with DHTMLX strucutre data
    if (!activityReactRef && isActivity) {
      activityReactRef = parentObj;
    } else {
      activityReactRef = gantt.getTask(parseInt(activityReactRef.unique_id));
    }
    const clonedObj = cloneDeep(task);
    clonedObj.calendarId = parentObj.calendar_id || gantt.defaultCalendar;
    clonedObj.parent_id = isActivity ? null : parent;
    clonedObj.activityId = activityReactRef.proplannerId;
    /** calculate the new correlative id */
    clonedObj.sectorId = currentSector.id;
    clonedObj.should_correct_start_date = false;
    clonedObj.ponderator = 0;
    clonedObj.progress = 0;
    clonedObj.checked = false;
    clonedObj.visibleChecked = false;
    // /PP-1553: Don't copy this fields because this code was resetting it
    // clonedObj.tags = []
    // clonedObj.responsables = []
    // clonedObj.cost = 0 // ?
    // clonedObj.description = null
    // clonedObj.hhWorkTime = null // ?
    // clonedObj.subcontractId = null
    clonedObj.used_cost = 0; // ?
    clonedObj.comesFromCopy = true;
    clonedObj.constraints = [];

    /**
     * cost budgeted
     */
    // PP-1564: reset this fields
    clonedObj.lean_status = 'Debit';
    clonedObj.remaining_quantity = 0;
    clonedObj.actual_quantity = 0;
    clonedObj.real_endowment = 0;
    clonedObj.spend_hh = 0;

    validateSubs(clonedObj);
    validateResponsables(clonedObj);
    validateTags(clonedObj);
    validateMaterials(clonedObj);
    validateLabor(clonedObj);
    validateEquipment(clonedObj);
    delete clonedObj.proplannerId;
    delete clonedObj.id;
    delete clonedObj.mustApp;
    delete clonedObj.mustApplyVisibleChecked;
    delete clonedObj.activityReference;
    delete clonedObj.children;
    const res = await taskService.create(clonedObj);
    // This both attr must be assigned after await
    clonedObj.id = res.id;
    clonedObj.children = [];
    // React ref use activityReference as DHTMLX data structure
    clonedObj.activityReference = activityReactRef;
    const copiedParentId = parentObj[isActivity ? 'proplannerId' : 'id'];
    const parentTask = findDeepGetTask(window.activities, 'id', copiedParentId);
    parentTask[isActivity ? 'tasks' : 'children'].push(clonedObj);
    const dhtmlxRefAdapted = convertPPTask2PluginTask(
      clonedObj,
      null,
      activityReactRef
    );
    dhtmlxRefAdapted.parent = parent;
    return dhtmlxRefAdapted;
  };

  /**
   * This function handle basic behaviour to can paste an activity without errors
   * @param {*} activity Activity object to paste
   * @param {*} parent Parent ID to assign
   * @param {*} availableCalendars Array with DHTMLX calendar format
   * @returns True if process finish without error
   */
  const pasteActivity = (activity, parent, availableCalendars) => {
    // rename pasteTask
    const doesExistCurrentCalendarId = availableCalendars.find(
      (calendar) => calendar.id == activity.calendar_id
    );
    const currentSector = sector;
    const clonedObj = cloneDeep(activity);
    if (!doesExistCurrentCalendarId) {
      delete clonedObj.calendar_id;
      clonedObj.calendar_id = gantt.defaultCalendar;
    }
    clonedObj.parent = parent;
    /** calculate the new correlative id */
    const newIID = getNextUID(sector.id, gantt.lastUniqueCorrelativeIds, gantt);
    clonedObj.unique_correlative_id = newIID;
    clonedObj.sectorId = currentSector.id;
    clonedObj.constraint_date = gantt.date.parseDate(
      clonedObj.constraint_date,
      'xml_date'
    );
    clonedObj.is_lookahead = false;
    clonedObj.should_correct_start_date = false;
    clonedObj.is_critical = 'No';
    delete clonedObj.proplannerId;
    delete clonedObj.id;
    delete clonedObj.baselineObject;
    delete clonedObj.mustApp;
    delete clonedObj.mustApplyVisibleChecked;
    clonedObj.ponderator = 0;
    clonedObj.progress = 0;
    clonedObj.checked = false;
    clonedObj.visibleChecked = false;
    clonedObj.tasks = [];
    clonedObj.baseline_points = [];
    clonedObj.activityModifications = [];
    clonedObj.expected_progress_base = 0;
    clonedObj.tags = [];
    clonedObj.responsables = [];
    clonedObj.subcontractId = null;
    clonedObj.description = null;
    clonedObj.hhWorkTime = null;
    clonedObj.cost = 0;
    clonedObj.used_cost = 0;
    clonedObj.comesFromCopy = true;
    validateSubs(clonedObj);
    validateResponsables(clonedObj);
    validateTags(clonedObj);
    gantt.createTask(clonedObj);
    return clonedObj;
  };

  /**
   * This function launch a message with succesfull pasted behaviour
   * @param {*} currentIndex current index of pasted object
   * @param {*} arrayLarge total of elements to paste
   * @returns null if current index is not equals to large of this array
   */
  const handleSuccessPasting = (currentIndex, arrayLarge) => {
    const doesThisLastElement = currentIndex === arrayLarge - 1;
    if (!doesThisLastElement) return;
    launchMessage(t, arrayLarge, 'success');
    gantt.detachMultiDragSelect();
    gantt.$keyboardNavigation.dispatcher.activeNode = null;
    gantt.$keyboardNavigation.dispatcher.isActive = false;
    updateAllCorrelatives();
    gantt.render();
  };

  const updateAllCorrelatives = () => {
    gantt.batchUpdate(() => {
      const tasks = gantt.serialize().data;
      tasks.forEach((task, index) => {
        if (!gantt.isTaskExists(task.id)) return;
        const t = gantt.getTask(task.id);
        t.correlative_id = index;
      });
    });
  };

  /**
   * This function handle the pasting of links
   * @param {*} linksToPaste array with links copied
   * @param {*} resultFromPastingActivities { newToOldHash: Object which maps new activities ID to old ID, oldToNewHash: same before but old to new, createdTasksRefArray: array of gantt instance added, toOpenParents: parents that were copied }
   */
  const pasteLinks = (linksToPaste, resultFromPastingActivities = {}) => {
    try {
      const mainActivity = gantt.getTaskByIndex(1);
      const { oldToNewHash } = resultFromPastingActivities;
      if (!mainActivity) return;
      linksToPaste.forEach((link) => {
        const copiedLink = cloneDeep(link);
        const newSource = oldToNewHash[link.source]?.id;
        const newTarget = oldToNewHash[link.target]?.id;
        if (newSource && newTarget) {
          copiedLink.source = newSource;
          copiedLink.target = newTarget;
          delete copiedLink.id;
          delete copiedLink.proplannerId;
          copiedLink.sectorId = sector.id;
          copiedLink.ganttId = mainActivity.ganttId;
          gantt.addLink(copiedLink);
          trackingEvent(
            'try_add_link',
            {
              getBasicAmplitudEventProperties,
              location: 'from pasteLinks in paste.api.js'
            },
            AMPLITUDE_SERVICE
          );
        }
      });
    } catch (e) {
      console.log('error on links psting process, please check...');
    }
  };

  /**
   * This function check, if copied sub, does exist in this context
   * @param {*} activity Activity to be checked
   */
  const validateSubs = (activity) => {
    const doesExist = gantt.subContracts.find(
      (subOption) => subOption.id === activity.subcontractId
    );
    if (!doesExist) {
      activity.subcontractId = null;
    }
  };

  /**
   * This function check if copied responsables, does exist in the context of the sector
   * @param {*} activity Activity to be checked
   */
  const validateResponsables = (activity) => {
    validateArrayOption(
      activity,
      'responsables',
      gantt.toSelectResponsables,
      'id'
    );
  };

  /**
   * This function validate if copied tags, does exist in the context of the sector
   * @param {*} activity Activity to be checked
   */
  const validateTags = (activity) => {
    validateArrayOption(activity, 'tags', gantt.toSelectTags, 'id');
  };

  /**
   * This function validate if copied materialId, does exists in the context of the current sector
   * @param {*} activity Activity to be checked
   */
  const validateMaterials = (activity) => {
    if (!activity.materialId) {
      return;
    }

    const existsInGannt = idFieldValidation('materials', activity.materialId);

    activity.materialId = existsInGannt ? activity.materialId : null;
  };

  /**
   * This function validate if copied laborId, does exists in the context of the current sector
   * @param {*} activity Activity to be checked
   */
  const validateLabor = (activity) => {
    if (!activity.specialtyId) {
      return;
    }

    const existsInGannt = idFieldValidation(
      'specialties',
      activity.specialtyId
    );

    activity.specialtyId = existsInGannt ? activity.specialtyId : null;
  };

  /**
   * This function validate if copied machineId, does exists in the context of the current sector
   * @param {*} activity Activity to be checked
   */
  const validateEquipment = (activity) => {
    if (!activity.machineId) {
      return;
    }

    const existsInGannt = idFieldValidation('machineries', activity.machineId);

    activity.machineId = existsInGannt ? activity.machineId : null;
  };

  /**
   * This function validate if copied materialId, does exists in the context of the current sector
   * @param {*} attr Name attribute of the array
   * @param {*} idToFind Value to by find inside the array
   */
  const idFieldValidation = (attr, idToFind) => {
    const existsInGantt = gantt[attr].find(
      (option) => option.value === idToFind
    );
    return Boolean(existsInGantt);
  };

  /**
   * This function validates that an attr from an activity, is available in current context (sector and project)
   * @param {*} activity Activity to check some attribute that is an array of options
   * @param {*} attr Name attribute which is a array of option, as tags, responsables, or future columns added
   * @param {*} optionsToSelect Array with available options for that attribute
   * @param {*} filterBy Attribute name which is going to be compared to check if there is exist the option assigned
   */
  const validateArrayOption = (activity, attr, optionsToSelect, filterBy) => {
    const finalResToAssign = [];
    activity &&
      activity[attr] &&
      activity[attr].forEach((toValidate) => {
        const doesExist = optionsToSelect.find(
          (option) => option[filterBy] === toValidate[filterBy]
        );
        if (doesExist) {
          finalResToAssign.push(toValidate);
        }
      });
    activity[attr] = finalResToAssign;
  };

  return {
    masterplan: () => {
      const timeoutCallback = () => {
        launchMessage(t, [], 'loading');
        const toCopyActivityId = selectedTasksDHTMLX[0];
        const toCopyActivity = gantt.getTask(toCopyActivityId);
        /** Validation */
        if (toCopyActivity) {
          const availableCalendars = gantt.getCalendars();
          /** If activity has child, then copied activities will be children */
          if (gantt.hasChild(toCopyActivityId)) {
            // pasteActivities(selectedTasksProplanner, availableCalendars, toCopyActivityId)
            launchBatchUpdate({
              selectedTasksProplanner,
              parent: toCopyActivityId,
              initialIndexRef: 0,
              pasteElement: (activity) =>
                pasteActivity(activity, toCopyActivityId, availableCalendars)
            });
          } else {
            const parentRef = gantt.getTask(toCopyActivity.parent);
            const parentIndex = parentRef && parentRef.$index;
            const toPasteIndex = toCopyActivity.$index;
            const indexRef = toPasteIndex - parentIndex;
            if (indexRef < 0) return;
            launchBatchUpdate({
              selectedTasksProplanner,
              parent: toCopyActivity.parent,
              initialIndexRef: indexRef,
              pasteElement: (activity) =>
                pasteActivity(
                  activity,
                  toCopyActivity.parent,
                  availableCalendars
                )
            });
            // pasteActivities(selectedTasksProplanner, availableCalendars, toCopyActivity.parent, indexRef)
          }
        }
        gantt.render();
      };

      timerManager.registerAutoTimeout(timeoutCallback, 500, 'masterplanPaste');
    },
    lookahead: () => {
      setTimeout(() => {
        const toCopyActivityId = selectedTasksDHTMLX[0];
        const toCopyActivity = gantt.getTask(toCopyActivityId);
        if (toCopyActivity.type === 'milestone') return;
        launchMessage(t, [], 'loading');
        /** Validation */
        if (toCopyActivity) {
          const availableCalendars = gantt.getCalendars();
          const currentActivity = window.activities.find(
            (activity) =>
              activity.id ===
              (toCopyActivity.type === 'activitytask'
                ? toCopyActivity.activityReference.proplannerId
                : toCopyActivity.proplannerId)
          );
          /** If activity has child, then copied activities will be children */
          if (gantt.hasChild(toCopyActivityId)) {
            // pasteActivities(selectedTasksProplanner, availableCalendars, toCopyActivityId)
            launchBatchUpdate({
              selectedTasksProplanner,
              parent: toCopyActivityId,
              initialIndexRef: 0,
              pasteElement: async (activity) =>
                await pasteTask(activity, toCopyActivityId, availableCalendars),
              currentActivity
            });
          } else {
            const parentRef = gantt.getTask(toCopyActivity.parent);
            const parentIndex = parentRef && parentRef.$index;
            const toPasteIndex = toCopyActivity.$index;
            const indexRef = toPasteIndex - parentIndex;
            if (indexRef < 0) return;
            let parent = toCopyActivity.parent;
            // Empty activity: So pasted tasks will have this empty activity as parent
            if (!toCopyActivity.parent) {
              parent = toCopyActivity.id;
            }

            // pasteActivities(selectedTasksProplanner, availableCalendars, toCopyActivity.parent, indexRef)
            launchBatchUpdate({
              selectedTasksProplanner,
              parent,
              initialIndexRef: indexRef,
              pasteElement: async (activity) =>
                await pasteTask(activity, parent, availableCalendars),
              currentActivity
            });
          }
        }
        gantt.render();
      }, 500);
    }
  };
}
