import { getFormatDate } from '../helpers/formateDate';
import { LANDSCAPE } from '../constants';

const MARGIN_ELEMENT = '<div style="height:5px; width:30px"></div>';
const LEGEND_IMAGES_BASE_URL =
  'https://proplannerv2.s3.us-east-2.amazonaws.com/images/pdf_legend';
const LEGEND_IMAGES = {
  critical: 'critical',
  nonCritical: 'non_critical',
  completed: 'completed',
  overdue: 'overdue',
  ahead: 'ahead',
  uninitiated: 'uninitiated',
  parentActivity: 'parent_activity',
  childActivity: 'child_activity',
  baseline: 'baseline',
  milestone: 'milestone',
  links: 'links',
  float: 'float'
};

const getLegendImagesSrc = (pageOrientation) => {
  const sizeVariation = pageOrientation === LANDSCAPE ? '' : '-small';

  return Object.fromEntries(
    Object.entries(LEGEND_IMAGES).map(([key, imageName]) => [
      key,
      `${LEGEND_IMAGES_BASE_URL}/${imageName}${sizeVariation}.png`
    ])
  );
};

/**
 * Generates the HTML for the status or critical path indicators in the PDF footer based on the Gantt chart configuration.
 *
 * @param {Object} gantt - The Gantt chart instance, containing configuration and visualization settings.
 * @param {Object} gantt.config - The configuration settings of the Gantt chart.
 * @param {boolean} gantt.config.highlight_critical_path - Indicates if the critical path should be highlighted.
 * @param {string} gantt.visualizationColorActive - The active visualization color setting, e.g., 'status'.
 *
 * @returns {string} The HTML string for the status or critical path indicators, or an empty string if no indicators are active.
 */
const getOptionStatus = (gantt, images) => {
  const { config, visualizationColorActive } = gantt;
  const { highlight_critical_path: highlightCriticalPath } = config;

  if (highlightCriticalPath) {
    return `
      ${MARGIN_ELEMENT}${MARGIN_ELEMENT}
      <div class="footer_container_icons_column">
        <span class="footer_container_icons_column_title">Critical Path</span>
        ${MARGIN_ELEMENT}${MARGIN_ELEMENT}
        <div class="footer_container_items">
          <div class="footer_container_icons_column_item">
            <span><img class="footer-icon" src="${images.critical}" /> Critical</span>
            ${MARGIN_ELEMENT}
            <span><img class="footer-icon" src="${images.nonCritical}" /> Non Critical</span>
          </div>
        </div>
      </div>`;
  }

  if (visualizationColorActive === 'status') {
    return `
      ${MARGIN_ELEMENT}${MARGIN_ELEMENT}
      <div class="footer_container_icons_column">
        <span class="footer_container_icons_column_title">Status</span>
        ${MARGIN_ELEMENT}${MARGIN_ELEMENT}
        <div class="footer_container_items">
          <div class="footer_container_icons_column_item">
            <span>
              <div>
                <img class="footer-icon" src="${images.completed}" /> 
              </div>
              <span>Completed</span>
              ${MARGIN_ELEMENT}
            </span>
            ${MARGIN_ELEMENT}
            <span>
              <div>
                <img class="footer-icon" src="${images.overdue}" /> 
              </div>
              <span>Overdue</span>
              ${MARGIN_ELEMENT}
            </span>
          </div>
          <div class="footer_container_icons_column_item">
            <span>
              <div>
                <img class="footer-icon" src="${images.ahead}" /> 
              </div>
              <span>Ahead</span>
              ${MARGIN_ELEMENT}
            </span>
            ${MARGIN_ELEMENT}
            <span>
              <div><img class="footer-icon" src="${images.uninitiated}" /> </div>
              <span>Uninitiated</span>
              ${MARGIN_ELEMENT}
            </span>
          </div>
        </div>
      </div>`;
  }

  return '';
};

/**
 * Generates the HTML for the schedule indicators in the PDF footer if the schedule mode is active.
 *
 * @param {boolean} isSchedule - Indicates whether the schedule mode is active.
 *
 * @returns {string} The HTML string for the schedule indicators, or an empty string if the schedule mode is inactive.
 */
const getOptionSchedule = (isSchedule, images) => {
  if (!isSchedule) {
    return '';
  }

  return ` 
    <div class="footer_container_icons_column_item">
      <span>
        <div style="width: 70px !important">
          <img class="footer-icon" src="${images.baseline}" /> 
        </div>
        <span>Baseline</span>
        ${MARGIN_ELEMENT}
      </span>
      ${MARGIN_ELEMENT}
      <span>
        <div style="width: 70px !important">
          <span style="text-align: center; font-size: 13px;">
            <p>(+# days)</p>
          </span>
        </div>
        <span>Delay</span>
        ${MARGIN_ELEMENT}
      </span>
    </div>`;
};

/**
 * Generates the HTML for the child activity or child task indicator in the PDF footer.
 *
 * @param {boolean} isSchedule - Determines whether the name should be 'Child Activity' (if true) or 'Child Task' (if false).
 * @returns {string} The HTML string for the child activity or child task indicator.
 */
const getOptionChild = (isSchedule, images) => {
  const name = isSchedule ? 'Child Activity' : 'Child Task';
  return `
    <span>
      <div>
        <img class="footer-icon" src="${images.childActivity}" />
      </div>
      <span>${name}</span>
      ${MARGIN_ELEMENT}
    </span>`;
};

const getLegend = ({ gantt, isSchedule, showLegend, pageOrientation }) => {
  if (!showLegend) {
    return '<div style="height: 120px;"></div>';
  }

  const images = getLegendImagesSrc(pageOrientation);
  const optionStatus = getOptionStatus(gantt, images);
  const optionSchedule = getOptionSchedule(isSchedule, images);
  const optionChild = getOptionChild(isSchedule, images);

  return `
    <div class="footer_container_icons">
      <div class="footer_container_icons_column">
        <span class="footer_container_icons_column_title">General</span>
        ${MARGIN_ELEMENT}${MARGIN_ELEMENT}
        <div class="footer_container_items">
          <div class="footer_container_icons_column_item">
            <span>
              <div>
                <img class="footer-icon" src="${images.parentActivity}" /> 
              </div>
              <span>Parent Activity</span>
              ${MARGIN_ELEMENT}
            </span>
            ${MARGIN_ELEMENT}
            ${optionChild}
          </div>
          ${optionSchedule}
          <div class="footer_container_icons_column_item">
            <span>
              <div style="width:25px !important">
                <img class="footer-icon" src="${images.milestone}" style="height:15px !important" /> 
              </div>
              <span>Milestone</span>
              ${MARGIN_ELEMENT}
            </span>
          </div>
          <div class="footer_container_icons_column_item">
            <span class="footer_container_icons_column_item__links">
              <img class="footer-icon" src="${images.links}" />
              Links
            </span>
            ${MARGIN_ELEMENT}
            <span class="footer_container_icons_column_item__float" style="display:none">
              <img class="footer-icon" src="${images.float}" />
              Float
            </span>
          </div>
        </div>
      </div>
      ${optionStatus}
    </div>`;
};

/**
 * Generates the HTML for the footer section of the PDF export.
 *
 * @param {Object} gantt - The Gantt chart instance, used for configuration and data.
 * @param {Function} t - Translation function for localization.
 * @param {Date} date - The date to format and include in the footer.
 * @param {boolean} isSchedule - Determines if the schedule mode is active.
 * @param {string} pageOrientation - The orientation of the PDF page (landscape or portrait).
 * @param {boolean} [showLegend=true] - Whether to display the legend or not. Defaults to true.
 *
 * @returns {string} The HTML string for the footer section of the PDF.
 */
export const getFooterPDF = ({
  gantt,
  t,
  date,
  isSchedule,
  pageOrientation,
  showLegend = true
}) => {
  const OUTBUILD_LOGO =
    'https://proplannerv2.s3.us-east-2.amazonaws.com/pdf-gantt/outbuild-logo.png';
  const imageOutbuildHTML = `<img src="${OUTBUILD_LOGO}" class="img-pp" alt="logo-proplanner" />`;
  const printedOn = t('master_plan.printed_on');
  const page = t('master_plan.page');
  const today = getFormatDate({ gantt, date });
  const legend = getLegend({ gantt, isSchedule, showLegend, pageOrientation });

  return `
    <div class="footer_container footer_container__${pageOrientation}">
      <div style="display: flex;flex-direction: row">${MARGIN_ELEMENT}${imageOutbuildHTML}</div>   
      ${legend}
      <div class="footer_container_info">
        <span class="gantt-footer-info">
          <p class="gantt-footer-title">${printedOn}: </p>
          <p class="gantt-footer-data">${today} </p>
          ${MARGIN_ELEMENT}
        </span>
        <span id="gantt-footer-page" class="gantt-footer-info" style="visibility:hidden">
          <p class="gantt-footer-title">${page}: </p>
          <p class="gantt-footer-data">{{p}}/{{pt}} </p>
        </span>
      </div>
    </div>`;
};
