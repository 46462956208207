import { getLinkTypeTittle } from '../../../../../../js/gantt_lifecycle/lags-flow';

/**
 * Retrieves and processes the links from the gantt to export in P6 format.
 *
 * @param {Object} gantt - The gantt object containing task relationships.
 * @param {Object} projectState - The state of the project, including sector information.
 * @param {Object} elementIdMapping - A mapping of element IDs (tasks and links) for gantt export.
 * @returns {Array} An array of processed links with relevant data for P6 export.
 */
export const getLinksToExportP6 = async ({
  gantt,
  currentSector,
  elementIdMapping
}) => {
  const { hoursPerDay } = currentSector;
  const links = gantt.getLinks();

  return links.map((link) => {
    const { id, lag = 0, source, target } = link;
    const type_label = getLinkTypeTittle(gantt, link);

    return {
      id: elementIdMapping[`link_${id}`],
      lag: lag ? lag : 0,
      lagInDays: lag / hoursPerDay,
      source: elementIdMapping[`task_${source}`],
      target: elementIdMapping[`task_${target}`],
      type: type_label
    };
  });
};
