const copyColumns = [
  'correlative_id',
  'text',
  'unique_correlative_id',
  'start_date',
  'duration',
  'end_date',
  'progress',
  'expected_progress',
  'freeSlack',
  'totalSlack',
  'custom_predecessors',
  'custom_sucessors',
  'constraint_type',
  'calendar_id',
  'constraint_date',
  'real_work',
  'hhWorkTime',
  'cost',
  'used_cost',
  'cost_base',
  'real_cost',
  'ponderator',
  'is_critical',
  'start_base',
  'end_base',
  'expected_progress_base',
  'work_base',
  'duration_base',
  'description',
  'early_start',
  'early_finish',
  'late_start',
  'late_finish',
  'calendarDuration',
  'subcontractId',
  'tags'
];

const noPasteColumns = [
  'checked',
  'buttons',
  'status',
  'responsables',
  'subcontractId',
  'tags',
  'constraint_type',
  'calendar_id'
];

const copyColumnsLookaHead = [
  'name',
  'description',
  'start_date',
  'duration',
  'end_date',
  'progress',
  'ponderator',
  'lean_status',
  'cost',
  'expected',
  'expected_cost',
  'subcontractId',
  'tags',
  'taskRoute',
  'plan_endowment',
  'real_endowment',
  'commitment_percentaje',
  'unique_correlative_id'
];

const noPasteColumnsLookaHead = [
  'priority',
  'status',
  'lean_status',
  'responsible',
  'subcontractId',
  'tags',
  'checked',
  'actions'
];

const enterEvent = new KeyboardEvent('keydown', {
  key: 'Enter',
  code: 'Enter',
  keyCode: 13,
  which: 13,
  bubbles: true
});

const typesTagNameInput = ['INPUT', 'TEXTAREA'];
const typesTagNameContent = ['TD', 'DIV'];
const formatsDates = ['DD/MM/YYYY', 'DD/MM/YY', 'MM/DD/YY', 'MM/DD/YYYY'];
const formatsDuration = [
  'day',
  'days',
  'week',
  'weeks',
  'month',
  'months',
  'year',
  'years'
];
const validFormatRegexCustoms =
  /^(\d+(ff|FF|fs|FS|sf|SF|ss|SS)|\d+(ff|FF|fs|FS|sf|SF|ss|SS)[+-]\d+d)$/;

export {
  copyColumns,
  noPasteColumns,
  copyColumnsLookaHead,
  noPasteColumnsLookaHead,
  typesTagNameInput,
  typesTagNameContent,
  formatsDates,
  formatsDuration,
  validFormatRegexCustoms,
  enterEvent
};
