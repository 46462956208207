import { findDeepGetTask } from '../../../../components/GanttVisualization/GanttVisualization.helper';
import { copyPasteActions } from '../../../../redux/actions/copyPasteActions';
import cloneDeep from 'lodash/cloneDeep';
import { copyMessages, launchMessage as messageAPI } from './message.api';
import { getTypeNotification, notifyMessageCustom } from '../../../../utils';
import { ALERT_ICON } from '../icons';
import {
  copyToClipboard,
  extractCopiedText,
  extractCopiedTextLookahead,
  getColumnName,
  isInput,
  isValidColumn,
  isValidColumnLookahead,
  registerTrackingEvent,
  showMessage
} from './utils/copy';

const MAX_ELEMENTS_TO_COPY = 500;

function handleCopyTasks(gantt, dispatch, t, selectedTasks = []) {
  if (gantt.config.readonly) return;
  const launchMessage = messageAPI(true);
  if (!selectedTasks?.length) {
    launchMessage(t, [], 'noData');
    return;
  }

  function dispatchToState(key) {
    dispatch(copyPasteActions.copyContent(gantt.tasksToCopy, key));
  }

  function dettachHandlerAndSelection() {
    gantt.detachMultiDragSelect && gantt.detachMultiDragSelect();
    gantt.$keyboardNavigation.dispatcher.activeNode = null;
    gantt.$keyboardNavigation.dispatcher.isActive = false;
  }

  function checkLimitToCopy() {
    if (selectedTasks.length > MAX_ELEMENTS_TO_COPY) {
      const description = copyMessages(t, selectedTasks).tooMuchMessages
        .message;

      notifyMessageCustom({
        type: getTypeNotification(ALERT_ICON, 'withTitleIcon'),
        title: t('multiselect_copy_paste.too_much_title'),
        description
      });
      return false;
    }
    return true;
  }

  return {
    masterplan: () => {
      if (!checkLimitToCopy()) return;
      const sourceToCopy = [];
      const targetToCopy = [];
      gantt.batchUpdate(() => {
        selectedTasks.forEach((planeTask) => {
          if (!gantt.isTaskExists(planeTask.id)) return;
          const ganttTask = gantt.getTask(planeTask.id);
          if (!ganttTask) return;

          ganttTask.$source.forEach((linkId) => {
            sourceToCopy.push(linkId);
          });
          ganttTask.$target.forEach((linkId) => {
            targetToCopy.push(linkId);
          });
        });
        const allLinks = [...sourceToCopy, ...targetToCopy];
        const toCopyLinks = {};
        const finalLinksArray = [];
        allLinks.forEach((linkId) => {
          if (linkId !== undefined && !toCopyLinks[linkId]) {
            if (!gantt.isLinkExists(linkId)) return;
            const ganttLink = gantt.getLink(linkId);
            if (!ganttLink) return;
            toCopyLinks[linkId] = gantt.getLink(linkId);
            finalLinksArray.push(toCopyLinks[linkId]);
          }
        });
        gantt.tasksToCopy = JSON.stringify({
          selectedTasks,
          selectedLinks: finalLinksArray
        });
        dispatchToState('masterplan');
        dettachHandlerAndSelection();
        launchMessage(t, selectedTasks, 'success');
        gantt.render();
      });
    },
    lookahead: () => {
      if (!checkLimitToCopy()) return;
      gantt.batchUpdate(() => {
        let legacyArrayRef = selectedTasks.map((dhtmlxRef) => {
          if (dhtmlxRef.isTask) {
            const reactRef = findDeepGetTask(
              window.activities,
              'id',
              dhtmlxRef.id
            );
            if (reactRef) {
              const copy = cloneDeep(reactRef);
              delete copy.children;
              delete copy.tasks;
              delete copy.activityReference;
              return copy;
            }
          }
        });
        legacyArrayRef = legacyArrayRef.filter((reactRef) => Boolean(reactRef));
        gantt.tasksToCopy = JSON.stringify({ selectedTasks: legacyArrayRef });
        dispatchToState('lookahead');
        dettachHandlerAndSelection();

        launchMessage(t, selectedTasks, 'success');
        gantt.render();
      });
    }
  };
}

function handleCopyCell(gantt, t) {
  if (gantt.config.readonly) return;

  const activeElement = document.activeElement;

  if (!isInput(activeElement)) {
    const columnName =
      getColumnName(activeElement) ||
      getColumnName(activeElement.closest('td'));

    if (!isValidColumn(columnName)) {
      return showMessage(t, 'tooMuchMessages');
    }
  }

  const copiedText = extractCopiedText(activeElement, gantt);
  if (!copiedText) {
    return showMessage(t, 'empty');
  }

  copyToClipboard(copiedText);
  registerTrackingEvent(
    getColumnName(activeElement),
    'schedule_cell_copied',
    'SCHEDULE'
  );
  showMessage(t, 'success');
}

function handleCopyCellLookahead(gantt, t) {
  if (gantt.config.readonly) return;
  const activeElement = document.activeElement;

  if (!isInput(activeElement)) {
    const columnName =
      getColumnName(activeElement) ||
      getColumnName(activeElement.closest('td'));

    if (!isValidColumnLookahead(columnName)) {
      return showMessage(t, 'tooMuchMessages');
    }
  }

  const copiedText = extractCopiedTextLookahead(activeElement, gantt);

  if (
    !copiedText ||
    ['Enter Description', 'Ingrese Descripcion'].includes(copiedText)
  ) {
    return showMessage(t, 'empty');
  }

  copyToClipboard(copiedText);
  registerTrackingEvent(
    getColumnName(activeElement),
    'lookahead_cell_copied',
    'LOOKAHEAD'
  );
  showMessage(t, 'success');
}

export { handleCopyTasks, handleCopyCell, handleCopyCellLookahead };
