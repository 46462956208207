import { getBasicAmplitudEventProperties } from '../../../../../../analytics/utils';
import { trackingEvent } from '../../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../../analytics/constants';
import { showExportP6XMLAlert } from './notifyUser';

/**
 * Tracks an event related to network blocking for P6 XML export.
 *
 * @param {Function} t - Translation function for internationalization.
 */
export const trackingEventNetworkBlocking = ({ t }) => {
  const message = t('export_p6_xml.network_blocking_alert');

  trackingEvent(
    'p6_xml_network_warning_alert',
    {
      ...getBasicAmplitudEventProperties(),
      status_code: message
    },
    AMPLITUDE_SERVICE
  );
};

export const trackingEventP6XMLExportInitiated = () => {
  trackingEvent(
    'p6_xml_export_initiated',
    {
      ...getBasicAmplitudEventProperties()
    },
    AMPLITUDE_SERVICE
  );
  return;
};

export const trackingEventP6XMLExportCompleted = () => {
  trackingEvent(
    'p6_xml_export_completed',
    {
      ...getBasicAmplitudEventProperties()
    },
    AMPLITUDE_SERVICE
  );
  return;
};

export const trackingEventP6XMLExportError = ({ t, error_code }) => {
  showExportP6XMLAlert({ t, status: false });
  trackingEvent(
    'p6_xml_export_error',
    {
      ...getBasicAmplitudEventProperties(),
      error_code
    },
    AMPLITUDE_SERVICE
  );
  return;
};
