import { ActivitiesStorage } from './activities';
import { LinksStorage } from './links';

function ganttHasChanges() {
  return ActivitiesStorage.checkHasChanges() || LinksStorage.checkHasChanges();
}

function clearStorage(storage = 'activities') {
  if (storage === 'activities') {
    ActivitiesStorage.reset();
  }
  if (storage === 'links') {
    LinksStorage.reset();
  }
}

function killStorage() {
  ActivitiesStorage.kill();
  LinksStorage.kill();
}

export {
  ActivitiesStorage,
  LinksStorage,
  clearStorage,
  killStorage,
  ganttHasChanges
};
