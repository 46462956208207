import { createGanttElementIdMapping } from '../createGanttElementIdMapping';
import { getCalendarsToExportP6 } from './calendars';
import { getLinksToExportP6 } from './links';
import { getProjectToExportP6 } from './project';
import { getTasksToExportP6 } from './tasks';

/**
 * Retrieves the necessary resources for exporting a project to P6 format, including calendars, tasks, links, and project data.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.gantt - The gantt data used for mapping and task/links extraction.
 * @param {Object} params.projectState - The current project state, providing context for calendars and tasks.
 * @returns {Promise<Object>} A promise that resolves to an object containing the calendars, links, tasks, and project data for P6 export.
 */
export const getProjectResourcesForP6Export = async ({
  gantt,
  projectStateService
}) => {
  const calendars = await getCalendarsToExportP6({ projectStateService });
  const project = await getProjectToExportP6({ gantt, projectStateService });

  const elementIdMapping = createGanttElementIdMapping(gantt);
  const tasks = getTasksToExportP6({ gantt, elementIdMapping });
  const links = await getLinksToExportP6({
    gantt,
    projectStateService,
    elementIdMapping
  });

  return {
    calendars,
    links,
    tasks,
    project
  };
};
