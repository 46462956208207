import { getCalendarsForProjectState } from '../../../../../../../../utils/calendars';
import { getCurrentSectorState } from '../../../../../../../../utils/userUtils';

/**
 * Retrieves and processes the calendars for the project state to export in P6 format.
 *
 * @param {Object} projectState - The state of the project, including sector information.
 * @returns {Promise<Array>} A promise that resolves to an array of processed calendars with relevant data for P6 export.
 */
export const getCalendarsToExportP6 = async ({ projectStateService = {} }) => {
  const calendars = await projectStateService.getCalendars();
  const {
    hoursPerDay,
    hoursPerWeek,
    id: sectorId
  } = await projectStateService.getSector();

  return calendars.map((calendar) => {
    const {
      id,
      is_default: isDefault,
      name,
      shifts,
      exceptiondays: exceptionDays
    } = calendar;

    return {
      id,
      isDefault,
      name,
      shifts,
      hoursPerDay,
      hoursPerWeek,
      exceptionDays,
      projectId: sectorId
    };
  });
};
