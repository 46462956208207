import { sentryLogger } from '../../../monitor/monitor';
class LinksStorage {
  static links = new Map();
  static updatedLinks = new Map();
  static initialized = false;
  static linkedTasks = null;
  static autoSchedule = true;
  static cache = new Map();
  static eventIds = [];
  static hasChanges = false;
  static storageRef = null;

  static init(gantt) {
    if (this.initialized) {
      return;
    }
    const storage = gantt.getDatastore('link');
    this.storageRef = storage;
    let pullStorage = storage.pull;
    let linksId = Object.keys(pullStorage);
    this.initialized = true;

    if (linksId.length > 0) {
      this.infectCurrentDataInProxy(pullStorage, linksId);
    }

    storage.pull = this.infectLinksContainer(pullStorage);

    const onStoreUpdatedId = storage.attachEvent(
      'onStoreUpdated',
      this._handleStoreUpdated.bind(this, gantt)
    );

    const onAfterAddId = storage.attachEvent(
      'onAfterAdd',
      this._handleAfterAdd.bind(this)
    );

    this.eventIds.push(onStoreUpdatedId, onAfterAddId);
  }
  static _handleStoreUpdated(gantt, id, item, action) {
    if (shouldAvoidUpdateStorage(action, item, gantt)) {
      return;
    }

    if (!this.hasChanges) {
      this.hasChanges = true;
    }

    if (action === ACTIONS.DELETE) {
      this.links.delete(id);
    }

    this.updatedLinks.set(id, item);
    this.updateAutoSchedule(true);
  }

  static _handleAfterAdd(id, item) {
    this.links.set(id, item);
    this.autoSchedule = true;
  }

  static updateLinkedTasks(linkedTasks) {
    this.linkedTasks = linkedTasks;
    this.autoSchedule = false;
  }

  static updateAutoSchedule(autoSchedule = true) {
    this.autoSchedule = autoSchedule;
  }

  static getLinkedTasks() {
    return this.linkedTasks;
  }

  static shouldRecalculateLinkedTasks() {
    return this.autoSchedule;
  }

  static invalidateCache() {
    this.cache = new Map();
  }

  static setCache(id = 'global', relations) {
    if (
      !Array.isArray(relations) ||
      !relations.some(Boolean) ||
      relations.length <= 1
    ) {
      return;
    }

    this.cache.set(id, [...relations]);
  }

  static getCache(id = 'global') {
    return [...this.cache.get(id)];
  }

  static isCachedActive(id = 'global') {
    return this.cache.has(id);
  }

  static infectCurrentDataInProxy(storage, linksId) {
    linksId.forEach((id) => {
      return (storage[id] = new Proxy(storage[id], {
        set: (target, prop, value, receiver) => {
          LinksStorage.invalidateCache();
          return Reflect.set(target, prop, value, receiver);
        }
      }));
    });
  }

  static infectLinksContainer(storage) {
    return new Proxy(storage, {
      get: (target, prop) => {
        return Reflect.get(target, prop);
      },
      set: (target, prop, value, receiver) => {
        LinksStorage.invalidateCache();
        const proxiedValue = new Proxy(value, {
          set(innerTarget, innerProp, innerValue) {
            LinksStorage.invalidateCache();
            return Reflect.set(innerTarget, innerProp, innerValue);
          }
        });

        return Reflect.set(target, prop, proxiedValue, receiver);
      },
      deleteProperty: (target, prop) => {
        LinksStorage.invalidateCache();
        return Reflect.deleteProperty(target, prop);
      }
    });
  }

  static removeEvents() {
    const storage = this.storageRef;
    if (!storage) return;

    this.eventIds.forEach((eventId) => {
      storage.detachEvent(eventId);
    });
    this.eventIds = [];
  }

  static reset() {
    this.links.clear();
    this.updatedLinks.clear();
    this.hasChanges = false;
    this.invalidateCache();
  }

  static kill() {
    try {
      this.reset();
      this.removeEvents();
      this.initialized = false;
      this.storageRef = null;
    } catch (e) {
      sentryLogger('Error on LinksStorage kill', e);
    } finally {
      this.initialized = false;
    }
  }

  static checkHasChanges() {
    return this.hasChanges;
  }
}
const ACTIONS = {
  DELETE: 'delete',
  PAINT: 'paint'
};
function shouldAvoidUpdateStorage(action, item, gantt) {
  const { initAlreadyDid, is_autoscheduling } = gantt;

  if (!action || action === ACTIONS.PAINT) {
    return true;
  }

  if (item === false) {
    return true;
  }

  if (!initAlreadyDid || is_autoscheduling) {
    return true;
  }

  return false;
}

export { LinksStorage };
