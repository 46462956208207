function restoreParentToTask(parent, gantt) {
  if (!parent.originalMetadata || gantt.hasChild(parent.id)) return;

  const original = parent.originalMetadata;
  Object.assign(parent, {
    duration: original.duration,
    non_parsed_original_duration: original.nonParsedDuration,
    progress: original.progress,
    type: original.type,
    start_date: original.startDate,
    end_date: original.endDate
  });

  delete parent.originalMetadata;
}

export { restoreParentToTask };
