import moment from 'moment';
import { EXPORT_TIMEOUT_SECONDS, TIMEOUT_MARGIN } from '../constants';
import { getFormattedDate } from '../../../../../../utils/dateUtils';

const DATE_FORMAT_DEFAULT = 'MM/DD/YY';
const TIME_UNIT = 'days';

/**
 * Formats a date using the current date format from Gantt or a default format.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date.
 */
export const getFormatDate = ({ gantt, date }) => {
  const { currentDateFormat } = gantt;
  const currentDate = moment(date).format(
    currentDateFormat || DATE_FORMAT_DEFAULT
  );
  return currentDate;
};

/**
 * Checks if two dates are equal to the specified time unit.
 *
 * @param {Date} firstDate - The first date to compare.
 * @param {Date} secondDate - The second date to compare.
 * @returns {boolean} `true` if the dates are equal, otherwise `false`.
 */
export const areDatesEqual = (firstDate, secondDate) => {
  return moment(firstDate).isSame(secondDate, TIME_UNIT);
};

/**
 * Determines the earliest start date and the latest end date from a list of tasks.
 *
 * @param {Array<{ start_date: Date, end_date: Date }>} tasks - List of tasks with start and end dates.
 * @returns {{ startDate: moment.Moment, endDate: moment.Moment }} The earliest start date and the latest end date.
 */
const getRangeAllTasks = (tasks) => {
  let startDateAllTasks;
  let endDateAllTasks;

  tasks.forEach(({ start_date, end_date }) => {
    const startTask = moment(start_date);
    const endTask = moment(end_date);

    const isNewStartEarlier =
      !startDateAllTasks || startTask.isBefore(startDateAllTasks);
    const isNewEndLater = !endDateAllTasks || endTask.isAfter(endDateAllTasks);
    if (isNewStartEarlier) {
      startDateAllTasks = startTask;
    }

    if (isNewEndLater) {
      endDateAllTasks = endTask;
    }
  });

  return { startDate: startDateAllTasks, endDate: endDateAllTasks };
};

/**
 * Determines the earliest start date and the latest end date for tasks that are visible and not projects.
 *
 * @param {Array<{ start_date: Date, end_date: Date, visibleInExport: boolean, isProjectInExport: boolean }>} tasks - List of tasks with start and end dates, visibility status, and project status.
 * @returns {{ startDate: moment.Moment, endDate: moment.Moment }} The earliest start date and the latest end date for visible tasks.
 */

const getRangeVisibleTasks = ({ tasks, isTrimParent = false }) => {
  let startDateVisibleTasks;
  let endDateVisibleTask;

  tasks.forEach((task) => {
    const { start_date, end_date, visibleInExport, isProjectInExport } = task;

    const isHiddenProject = isProjectInExport && isTrimParent;

    if (!visibleInExport || isHiddenProject) {
      return;
    }

    const startTask = moment(start_date);
    const endTask = moment(end_date);

    const isNewStartEarlier =
      !startDateVisibleTasks || startTask.isBefore(startDateVisibleTasks);
    const isNewEndLater =
      !endDateVisibleTask || endTask.isAfter(endDateVisibleTask);

    if (isNewStartEarlier) {
      startDateVisibleTasks = startTask;
    }

    if (isNewEndLater) {
      endDateVisibleTask = endTask;
    }
  });

  return { startDate: startDateVisibleTasks, endDate: endDateVisibleTask };
};

/**
 * Calculates the percentage of the Gantt chart's visible range relative to the total range of all tasks.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @param {boolean} isTrimParent - Flag that indicates whether the Trim parent activities option is active.
 * @returns {number} The percentage of the visible range compared to the total range of all tasks, as a fraction between 0 and 1.
 */
export const calculateGanttVisibleRangePercentage = ({
  gantt,
  isTrimParent = false
}) => {
  const RETURN_DEFAULT = 1;

  const tasks = gantt.getTaskByTime();
  if (!tasks.length) {
    return RETURN_DEFAULT;
  }

  const rangeAllTasks = getRangeAllTasks(tasks);
  const rangeVisibleTasks = getRangeVisibleTasks({ tasks, isTrimParent });

  const projectTotalDays =
    rangeAllTasks.endDate.diff(rangeAllTasks.startDate, TIME_UNIT) + 1;
  const tasksVisibleDays =
    rangeVisibleTasks.endDate.diff(rangeVisibleTasks.startDate, TIME_UNIT) + 1;

  return tasksVisibleDays / projectTotalDays;
};

/**
 * Calculates the number of seconds elapsed since the export started.
 *
 * @param {number} date - The start time of the export.
 * @returns {number} - The seconds elapsed.
 */
export const calculateElapsedTimeInSeconds = (date) => {
  return moment().diff(date, 'seconds');
};

/**
 * Calculates the remaining time for the timeout. If more than EXPORT_TIMEOUT_SECONDS has passed,
 * it sets a minimum timeout of TIMEOUT_MARGIN.
 *
 * @param {number} secondsElapsed - The seconds elapsed since the export started.
 * @returns {number} - The remaining time for the timeout.
 */
export const calculateRemainingTimeout = (secondsElapsed) => {
  return EXPORT_TIMEOUT_SECONDS > secondsElapsed
    ? EXPORT_TIMEOUT_SECONDS - secondsElapsed
    : TIMEOUT_MARGIN;
};

/**
 * Generates a formatted date string suitable for a PDF filename.
 * The date is formatted as 'MM-DD-YYYY HH-mm-ss' to ensure compatibility with file naming conventions.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date for the PDF filename.
 */
export const getFormattedPdfDate = () => {
  const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
  const dateFormat = getFormattedDate({
    date: new Date(),
    customFormat: DATE_FORMAT
  });
  return dateFormat.replaceAll('/', '-');
};
