import { createP6XMLFile } from './helpers/createP6XMLFile';
import { activityPdfService } from '../../../../../services';
import { checkBackendAccess } from '../../../../../utils/checkBackendAccess';
import {
  showExportP6XMLAlert,
  showNetworkBlockAlert
} from './helpers/notifyUser';
import {
  trackingEventP6XMLExportCompleted,
  trackingEventP6XMLExportError,
  trackingEventP6XMLExportInitiated
} from './helpers/trackingEvent';
import { sentryLogger } from '../../../../../monitor/monitor';

/**
 * Exports a P6 XML file for a project by interacting with the backend and creating the XML file.
 * Handles backend access, tracking, and error handling.
 *
 * @param {Object} params - The parameters for exporting the P6 XML.
 * @param {Function} [params.t] - The translation function, used for localization (default: a no-op function).
 * @param {boolean} [params.isSchedule=true] - A flag to indicate whether the export is for a schedule (default: true).
 * @param {Object} params.projectState - The current state of the project, used to generate the P6 XML.
 * @returns {Promise<void>} A promise that resolves when the export is complete.
 */
export const ExportP6XML = async ({
  t = () => {},
  isSchedule = true,
  projectState
}) => {
  try {
    const statusServer = await checkBackendAccess(activityPdfService);
    if (!statusServer) {
      showNetworkBlockAlert({ t });
      return;
    }

    trackingEventP6XMLExportInitiated();
    const { status } = await createP6XMLFile({ isSchedule, projectState });
    showExportP6XMLAlert({ t, status });
    trackingEventP6XMLExportCompleted();
  } catch (error) {
    trackingEventP6XMLExportError({ t, error_code: error.message });
    sentryLogger.error('ExportP6XMLFunction', {
      error
    });
  }
};
