import { message as antdMessages, message } from 'antd';

export const copyMessages = (t, selectedTasksToCopy) => ({
  noData: {
    message: t('multiselect_copy_paste.content_no_data_to_copy'),
    type: 'warning'
  },
  tooMuchMessages: {
    message: `${t('multiselect_copy_paste.too_much_content_1')} ${selectedTasksToCopy?.length} ${t('multiselect_copy_paste.too_much_content_2')} `,
    type: 'error'
  },
  success: {
    message: `${selectedTasksToCopy?.length} ${t('multiselect_copy_paste.content_copied')}`,
    type: 'success'
  }
});

export const copyCellsMessages = (t) => ({
  noData: {
    message: t('cells_copy_paste.no_cells_selected'),
    type: 'warning'
  },
  tooMuchMessages: {
    message: t('cells_copy_paste.no_copy_cells'),
    type: 'error'
  },
  success: {
    message: t('cells_copy_paste.cells_copied'),
    type: 'success'
  },
  empty: {
    message: t('cells_copy_paste.empty_cell'),
    type: 'warning'
  }
});

const pasteMessages = (t, selectedTasks) => ({
  loading: {
    message: t('multiselect_copy_paste.pasting_msj'),
    type: 'loading'
  },
  success: {
    message: `${selectedTasks} ${t('multiselect_copy_paste.content_pasted')}`,
    type: 'success'
  },
  noData: {
    message: t('multiselect_copy_paste.content_no_data'),
    type: 'warning'
  },
  error: { message: t('multiselect_copy_paste.content_error'), type: 'error' }
});

export const pasteCellsMessages = (t) => ({
  success: {
    message: t('cells_copy_paste.cells_pasted'),
    type: 'success'
  },
  error: {
    message: t('cells_copy_paste.error_pasting_cells'),
    type: 'error'
  },
  noDate: {
    message: t('cells_copy_paste.no_date_validate'),
    type: 'error'
  },
  noNumber: {
    message: t('cells_copy_paste.no_number_validate'),
    type: 'error'
  },
  noPasteInFather: {
    message: t('cells_copy_paste.no_paste_in_father'),
    type: 'error'
  },
  noPasteInActivities: {
    message: t('cells_copy_paste.no_paste_in_activities'),
    type: 'error'
  }
});

function messages(t, selectedTasks, isCopy, isCopyCells, isPasteCells) {
  if (isCopyCells) {
    return copyCellsMessages(t);
  }
  if (isPasteCells) {
    return pasteCellsMessages(t);
  }
  return isCopy
    ? copyMessages(t, selectedTasks)
    : pasteMessages(t, selectedTasks);
}

const messageAPI = (
  t,
  selectedTasksProplanner,
  isCopy,
  isCopyCells,
  isPasteCells
) => messages(t, selectedTasksProplanner, isCopy, isCopyCells, isPasteCells);

export const launchMessage =
  (isCopy, isCopyCells = false, isPasteCells = false) =>
  (t, selectedItems, type, timer = 2) => {
    const messageOptions = messageAPI(
      t,
      selectedItems,
      isCopy,
      isCopyCells,
      isPasteCells
    )[type];
    antdMessages[messageOptions.type](messageOptions.message, timer);
  };
