import { BlobWriter, TextReader, ZipWriter } from '@zip.js/zip.js';
import { getCurrentSectorState } from '../../../../../../utils/userUtils';
import { sanitizeForFilename } from '../../ExportPDFGantt/utils/export.utils';

/**
 * Creates a Blob object from the provided content and type.
 *
 * @param {string} content - The content to be included in the Blob.
 * @param {string} type - The MIME type of the Blob.
 * @returns {Blob} The created Blob object.
 */
const createBlob = (content, type) => {
  return new Blob([content], { type });
};

/**
 * Compresses a file's content into a ZIP file using zip.js.
 *
 * @param {string} fileName - The name of the file to compress.
 * @param {string} content - The content to compress into the ZIP file.
 * @returns {Promise<Object>} A promise resolving to an object containing the ZIP Blob, final file name, and MIME type.
 */
const compressToZip = async (fileName, content) => {
  try {
    // Asegúrate de que el contenido no esté vacío
    if (!content || content.length === 0) {
      throw new Error('Content is empty or invalid');
    }

    // Crear un escritor de Blob para generar el archivo ZIP
    const blobWriter = new BlobWriter();
    const zipWriter = new ZipWriter(blobWriter);

    // Intentamos agregar el archivo al ZIP
    const textReader = new TextReader(content);
    await zipWriter.add(fileName, textReader, { level: 9 }); // Usamos nivel 9 para máxima compresión

    // Cerramos el archivo ZIP
    await zipWriter.close();

    // Obtener el Blob del archivo ZIP
    const zipBlob = await blobWriter.getData();

    // Verificamos el tamaño del archivo ZIP generado
    console.log(`Generated ZIP size: ${zipBlob.size} bytes`);

    // Retornamos el archivo ZIP
    return {
      contentToDownload: zipBlob,
      finalFileName: `${fileName}.zip`,
      mimeType: 'application/zip'
    };
  } catch (error) {
    console.error('Error during ZIP creation:', error);
    throw new Error('Failed to create ZIP file.', { cause: error });
  }
};

/**
 * Determines the download details based on the file size.
 *
 * @param {Object} params - The input parameters.
 * @param {Blob} params.xmlBlob - The Blob object representing the XML content.
 * @param {string} params.fileName - The name of the file.
 * @param {string} params.p6XMLString - The XML content as a string.
 * @returns {Promise<Object>} A promise resolving to an object containing the content to download, file name, and MIME type.
 */
const getDownloadDetails = async ({ xmlBlob, fileName, p6XMLString }) => {
  const MAX_SIZE_MB = 20;
  const BYTES_PER_MB = 1024 * 1024;
  const xmlSizeInMB = xmlBlob.size / BYTES_PER_MB;

  if (xmlSizeInMB > MAX_SIZE_MB) {
    return await compressToZip(fileName, p6XMLString);
  }

  return {
    contentToDownload: xmlBlob,
    finalFileName: fileName,
    mimeType: 'application/xml'
  };
};

/**
 * Initiates the download of a file using the provided download details.
 *
 * @param {Object} downloadDetails - The details for the file download.
 * @param {Blob} downloadDetails.contentToDownload - The content to be downloaded.
 * @param {string} downloadDetails.finalFileName - The name of the file to download.
 * @param {string} downloadDetails.mimeType - The MIME type of the file.
 */
const initiateDownload = (downloadDetails) => {
  const {
    contentToDownload: content,
    finalFileName: fileName,
    mimeType
  } = downloadDetails;
  const REVOKE_URL_DELAY_MS = 300;

  const blob = new Blob([content], { type: mimeType });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.href = url;
  link.download = fileName;
  link.click();

  setTimeout(() => URL.revokeObjectURL(url), REVOKE_URL_DELAY_MS);
};

/**
 * Creates and downloads an XML file from the provided P6 XML string.
 * If a new window is provided, the window is closed after the file is downloaded.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.projectState - The state of the project, used to determine the file name.
 * @param {boolean} params.newWindow - A flag indicating whether a new window should be closed after downloading the file.
 * @param {string} params.p6XMLString - The P6 XML content to be written to the file.
 */
export const createFileXml = async ({
  projectState,
  newWindow,
  p6XMLString
}) => {
  if (!newWindow) {
    return;
  }

  try {
    const fileName = getP6XMLName(projectState);
    const xmlBlob = createBlob(p6XMLString, 'application/xml');

    const downloadDetails = await getDownloadDetails({
      xmlBlob,
      fileName,
      p6XMLString
    });

    initiateDownload(downloadDetails);
  } catch (error) {
    throw new Error(`Error in createFileXml: ${error.message}`, {
      cause: error
    });
  }
};

/**
 * Generates a sanitized XML file name based on the current sector's project name.
 *
 * @param {Object} projectState - The state of the project.
 * @param {Object} projectState.sectorState - The state of the sector, which includes the project name.
 * @param {string} projectState.sectorState.name - The name of the project in the current sector.
 * @returns {string} The sanitized file name for the XML file, including the `.xml` extension.
 */
export const getP6XMLName = (projectState) => {
  const { name } = getCurrentSectorState(projectState);
  const sanitizedP6XMLName = sanitizeForFilename(name);
  return `${sanitizedP6XMLName}.xml`;
};
