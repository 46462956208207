import { checkBugResize } from './checkBugResize';
import { initializeTask } from './initializeTask';
import { updateActivityDuration } from './updateActivityDuration';
import { updateConstraintDate } from './updateConstraintDate';
import { updateMilestoneData } from './updateMilestoneData';
import { updateParentDuration } from './updateParentDuration';
import { LinksStorage } from '../../gantt_storage/links';
import { store } from '../../../../redux/store';

import {
  addHoursToConstraintDate,
  executeFixForConstraints,
  getTaskCalendar,
  updateTaskTiming
} from '../utils';
import { updateRelatedSubmittals } from './updateRelatedSubmittals';

function onAfterTaskUpdate({ id, task, calendarObject, sector, projectState }) {
  const gantt = window.to_use_react_gantt;

  const currentSubmittalState = store.getState().submittalState?.submittals;
  updateRelatedSubmittals(task, currentSubmittalState);

  const callBackAfterUpdate = () => {
    // PP-2494: This code validates if the update is triggered by the autoscheduling
    // If that is the case, we stop the heavy calculation
    if (
      gantt.is_autoscheduling ||
      gantt.getState().batch_update ||
      !gantt.isTaskExists(id)
    ) {
      if (!gantt.ext.undo.undoDisabled) return;
    }

    const activity = gantt.getTask(id);
    if (gantt.loadedSuccesfully && !gantt.isPasting) {
      const calendar = getTaskCalendar(activity, gantt);
      updateActivityDuration(activity);

      if (!gantt.is_task_moved && !gantt.completingOnProcess) {
        updateMilestoneData(activity);
      }

      updateParentDuration(activity, id, projectState);

      if (gantt.completingOnProcess) return;

      checkBugResize(activity);
      executeFixForConstraints(activity, calendar);
      addHoursToConstraintDate(activity, calendar, gantt);
      initializeTask(activity, sector);

      if (activity.type === 'milestone') {
        delete activity.color;
      } else {
        if (!gantt.isDragging) {
          updateTaskTiming({ task: activity, gantt });
        }
      }

      updateConstraintDate(activity, calendarObject);

      /** ctid#13 mp-252 */
      if (activity.duration_new && activity.end_date_new) {
        activity.duration = activity.duration_new;
        activity.for_disable_milestone_duration = activity.duration;
        const dateStr = activity.end_date_new;
        if (Object.keys(calendarObject.customHour).length !== 0) {
          const hours = calendarObject.customHour.endHour.split(':')[0];
          const minutes = calendarObject.customHour.endHour.split(':')[1];
          dateStr.setHours(hours);
          dateStr.setMinutes(minutes);
        }
        activity.end_date = dateStr;
        delete activity.end_date_new;
      }
    }

    if (
      activity.comesFromCopy &&
      activity.old_type === 'project' &&
      activity.type !== 'project'
    ) {
      activity.type = activity.old_type;
    }
    gantt.lastUpdatedColumn = null;
    const shouldDoMaxPerformace = Boolean(gantt.isDragging);

    if (!gantt.isPasting) gantt.refreshData();
    gantt.maxPerformance = shouldDoMaxPerformace;

    const breakAutoSchedule = () => {
      let countAutoSchedule = gantt.countAutoSchedule || [];
      const lengthCount = countAutoSchedule.length;
      if (countAutoSchedule[lengthCount - 1] !== id) countAutoSchedule = [];
      countAutoSchedule.push(id);
      gantt.countAutoSchedule = countAutoSchedule;
      return countAutoSchedule.length <= 5;
    };
    if (gantt.added_link && !activity.comesFromUndo) {
      if (breakAutoSchedule()) {
        gantt.ext.undo.ignoreAutoSchedule = true;
        if (!gantt.isPasting) gantt.autoSchedule();
        gantt.ext.undo.ignoreAutoSchedule = false;
      }
      gantt.added_link = false;
    }
  };

  callBackAfterUpdate();
}

export { onAfterTaskUpdate };
