/**
 * Retrieves and sorts tasks from a gantt by their correlative ID.
 *
 * @param {Object} gantt - The gantt object containing tasks.
 * @returns {Array} - Sorted array of tasks based on correlative ID.
 */
export const getTasksFromGantt = (gantt) => {
  const tasks = [...gantt.getTaskByTime()];
  return tasks.sort((a, b) => a.correlative_id - b.correlative_id);
};
