import moment from 'moment';
import { ganttAPI } from '../../../../utils/customGanttPlugin';

const linkTypeMap = {
  0: { sourceField: 'end_date', targetField: 'start_date' }, // Finish-to-Start
  1: { sourceField: 'start_date', targetField: 'start_date' }, // Start-to-Start
  2: { sourceField: 'end_date', targetField: 'end_date' }, // Finish-to-Finish
  3: { sourceField: 'start_date', targetField: 'end_date' }, // Start-to-Finish
  default: { sourceField: 'end_date', targetField: 'start_date' }
};

function modifyLagCustom(task) {
  const gantt = window.to_use_react_gantt;

  (task.$target || []).forEach((linkId) => {
    const NO_REPAINT = true;

    gantt.batchUpdate(() => {
      const link = gantt.getLink(linkId);
      if (!gantt.isTaskExists(link.source)) return;

      const sourceTask = gantt.getTask(link.source);
      const { sourceField, targetField } =
        linkTypeMap[link.type] || linkTypeMap.default;

      const sourceDate = moment(sourceTask[sourceField]).format();
      const targetDate = moment(task[targetField]).format();

      let lag = ganttAPI.calculateDuration(
        sourceDate,
        targetDate,
        task.calendar_id,
        gantt
      );

      if (sourceTask.constraint_type === 'alap') {
        lag = 0;
      }

      link.lag = lag;
    }, NO_REPAINT);
  });
}

export { modifyLagCustom };
