import { getCurrentSectorState } from '../../../../../../utils/userUtils';
import { sanitizeForFilename } from '../../ExportPDFGantt/utils/export.utils';

/**
 * Creates and downloads an XML file from the provided P6 XML string.
 * If a new window is provided, the window is closed after the file is downloaded.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.projectState - The state of the project, used to determine the file name.
 * @param {boolean} params.newWindow - A flag indicating whether a new window should be closed after downloading the file.
 * @param {string} params.p6XMLString - The P6 XML content to be written to the file.
 */
export const createFileXml = ({ projectState, newWindow, p6XMLString }) => {
  const REVOKE_URL_DELAY_MS = 300;
  if (!newWindow) {
    return;
  }
  try {
    const blob = new Blob([p6XMLString], { type: 'application/xml' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = getP6XMLName(projectState);
    link.click();
    setTimeout(() => URL.revokeObjectURL(url), REVOKE_URL_DELAY_MS);
  } catch (error) {
    throw new Error(`Error in createFileXml: ${error.message}`, {
      cause: error
    });
  }
};

/**
 * Generates a sanitized XML file name based on the current sector's project name.
 *
 * @param {Object} projectState - The state of the project.
 * @param {Object} projectState.sectorState - The state of the sector, which includes the project name.
 * @param {string} projectState.sectorState.name - The name of the project in the current sector.
 * @returns {string} The sanitized file name for the XML file, including the `.xml` extension.
 */

export const getP6XMLName = (projectState) => {
  const { name } = getCurrentSectorState(projectState);
  const sanitizedP6XMLName = sanitizeForFilename(name);
  return `${sanitizedP6XMLName}.xml`;
};
