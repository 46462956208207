import { getFormattedDateToP6XML } from '../../formateDate';
import { getTasksFromGantt } from './getTasksFromGantt';

/**
 * Extracts and formats task data from the gantt for P6 export.
 *
 * @param {Object} params - The parameters for extracting tasks.
 * @param {Object} params.gantt - The gantt object containing the tasks.
 * @param {Object} params.elementIdMapping - The mapping of task IDs to indices.
 * @returns {Array<Object>} An array of task objects formatted for P6 export.
 * Each task object contains properties like type, duration, startDate, endDate, and others.
 */
export const getTasksToExportP6 = ({ gantt, elementIdMapping }) => {
  const TASK_OFFSET = 1;
  const tasks = getTasksFromGantt(gantt);

  return tasks.map((task) => {
    const {
      start_date: startDate,
      end_date: endDate,
      duration,
      type,
      calendar_id: calendarId,
      text: name,
      progress,
      parent,
      id,
      proplannerId,
      correlative_id: index,
      $local_index: localIndex,
      $level: level,
      constraint_type: constraintType,
      constraint_date: constraintDate
    } = task;

    return {
      type,
      duration,
      startDate: getFormattedDateToP6XML(startDate),
      endDate: getFormattedDateToP6XML(endDate),
      calendarId,
      name,
      id: index + TASK_OFFSET,
      taskId: id,
      proplannerId,
      parentId: elementIdMapping[`task_${parent}`],
      index,
      localIndex: localIndex + TASK_OFFSET,
      level,
      progress,
      constraintType,
      constraintDate: constraintDate
        ? getFormattedDateToP6XML(constraintDate)
        : null
    };
  });
};
