import { trackingEvent } from '../../../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../../../analytics/utils';
import notificationSystemV2 from '../../../../../../components/DesignSystem/NotificationSystemV2/NotificationSystemV2';
import { getFormatDate } from './formateDate';
import { setupGanttAfterPDFGeneration } from './updateGantt';
import * as Sentry from '@sentry/react';
import { ZOOM_LEVELS_GANTT } from '../constants';
import { getGanttToExport } from '../utils/gantt.utils';

/**
 * Tracks an event related to network blocking for PDF export.
 *
 * @param {Function} t - Translation function for internationalization.
 * @param {boolean} isSchedule - Indicates if the event is related to the schedule.
 */
export const trackingEventNetworkBlocking = ({ t, isSchedule }) => {
  const scheduleEvent = 'schedule_pdf_network_warning_alert';
  const lookaheadEvent = 'lookahead_pdf_network_warning_alert';
  const message = t('export_pdf_modal.network_blocking_alert');

  const eventName = isSchedule ? scheduleEvent : lookaheadEvent;

  trackingEvent(
    eventName,
    {
      ...getBasicAmplitudEventProperties(),
      status_code: message
    },
    AMPLITUDE_SERVICE
  );
};

/**
 * Tracks the result of a PDF export event.
 *
 * @param {Object} gantt - The Gantt chart instance.
 * @param {boolean} isSchedule - Indicates if the export is related to the schedule.
 * @param {Object} configPage - Configuration settings for the export.
 * @param {boolean} configPage.isFitOnePage - Whether the export is set to fit one page wide.
 * @param {boolean} configPage.isTrimParent - Whether parent activities are trimmed.
 * @param {string} configPage.formatToExport - The format of the export.
 * @param {string} configPage.pageOrientation - The orientation of the PDF page (landscape or portrait).
 * @param {boolean} configPage.isShowTodayLinePDF - Whether to show today's line in the PDF.
 * @param {Date} configPage.printedOn - The date selected for the export.
 * @param {boolean} configPage.showFooterLegend - Whether to show the footer legend the PDF.
 * @param {boolean} configPage.showOrganizationLogo - Whether to show the organization logo in the PDF.
 * @param {boolean} configPage.showOwnerLogo - Whether to show the owner logo in the PDF.
 * @param {string} eventResult - The result of the export event.
 * @param {string} exportableTitle - The title of the exportable. "Default title" if the title is the same as the project name.
 */
export const trackingEventExportPDFResult = ({
  gantt,
  isSchedule,
  zoomLevel,
  configPage,
  eventResult,
  exportName,
  exportableTitle
}) => {
  const FIT_TO_ONE_PAGE = 'Fit to one page wide';
  const {
    isFitOnePage,
    isTrimParent,
    formatToExport,
    pageOrientation,
    isShowTodayLinePDF,
    printedOn,
    makeAvailableForIpad,
    showFooterLegend,
    showOrganizationLogo,
    showOwnerLogo
  } = configPage;

  const printDate = getFormatDate({ gantt, date: printedOn });
  const zoomLevelText = ZOOM_LEVELS_GANTT[zoomLevel] || '';

  const propsAmplitud = {
    page_format: formatToExport,
    page_orientation: pageOrientation,
    print_date_selected: printDate,
    today_line_aligned: isShowTodayLinePDF,
    trim_parent_activities: isTrimParent,
    zoom_level: zoomLevelText,
    visibility_option_selected: [
      showOrganizationLogo && 'Show Organization Logo',
      showOwnerLogo && 'Show Owner Logo',
      showFooterLegend && 'Show Legend'
    ].filter(Boolean),
    eventResult,
    exportName,
    makeAvailableForIpad,
    exportable_title: exportableTitle
  };

  if (isSchedule) {
    trackingEvent(
      'schedule_pdf_exportation',
      {
        ...getBasicAmplitudEventProperties(),
        ...propsAmplitud,
        scale_option_selected: isFitOnePage ? FIT_TO_ONE_PAGE : null
      },
      AMPLITUDE_SERVICE
    );
    return;
  }

  const { id: viewId, label: viewName } = window.defaultView.current;

  trackingEvent(
    'lookahead_pdf_exportation',
    {
      ...getBasicAmplitudEventProperties(),
      ...propsAmplitud,
      lookahead_view_name: viewName,
      lookahead_view_id: viewId,
      fit_to_one_page: isFitOnePage
    },
    AMPLITUDE_SERVICE
  );
};

export const trackingEventUnknown = ({ t, isSchedule }) => {
  try {
    const gantt = getGanttToExport(isSchedule);
    setupGanttAfterPDFGeneration({
      gantt,
      isSchedule
    });

    notificationSystemV2({
      key: 'exportable-error-unknown',
      type: 'error',
      message: t('export_pdf_gantt.notification.error')
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
