import { getTasksFromGantt } from '../resources/tasks/getTasksFromGantt';

/**
 * Creates a mapping of task and link IDs to their respective indices in the gantt.
 *
 * @param {Object} gantt - The gantt object.
 * @returns {Object} An object mapping task and link IDs to their corresponding indices.
 * The keys are in the format `task_{id}` or `link_{id}` and the values are the indices.
 */
export const createGanttElementIdMapping = (gantt) => {
  const tasks = getTasksFromGantt(gantt);
  const links = gantt.getLinks();
  const taskCount = tasks.length;

  const TASK_OFFSET = 1;
  const LINK_OFFSET = taskCount;

  const idMap = tasks.reduce((map, { id, correlative_id }) => {
    map[`task_${id}`] = correlative_id + TASK_OFFSET;
    return map;
  }, {});

  links.forEach(({ id }, index) => {
    idMap[`link_${id}`] = LINK_OFFSET + index + TASK_OFFSET;
  });

  return idMap;
};
