import { ALL_CONTENT, formatOnPixels, zoomLevelConfig } from '../constants';
import {
  calculateGanttTotalHeigth,
  calculateGanttTotalWidth,
  getZoomLevel
} from './updateGantt';

/**
 * Checks if the export format indicates that all content should be included.
 *
 * @param {string} formatToExport - The format of the export.
 * @returns {boolean} - `true` if all content should be included, otherwise `false`.
 */
export const formatIsAllContent = (formatToExport) => {
  return formatToExport === ALL_CONTENT;
};

/**
 * Determines the language code for PDF export based on the user's browser language.
 *
 * @returns {string} - The language code (`'en'` for English or `'es'` for Spanish).
 */
export const langExportPDF = () => {
  const EN = 'en';
  const ES = 'es';

  const userLang = navigator.language || navigator.userLanguage;
  const lang = userLang.includes(ES) ? ES : EN;
  return lang;
};

/**
 * Retrieves the column width for the Gantt chart based on the current zoom level and content settings.
 *
 * @param {Object} params - The parameters object.
 * @param {Gantt} params.gantt - The Gantt chart instance.
 * @param {boolean} params.isAllContent - Indicates whether all content should be displayed.
 * @returns {number} - The width of the column in pixels.
 */
export const getColumnWidthZoom = ({ gantt, isAllContent }) => {
  const ZOOM_LEVEL_WEEK = 3;
  const WIDTH_WEEK_ALL_CONTENT = 80;

  const zoomLevel = getZoomLevel(gantt);
  if (isAllContent && zoomLevel === ZOOM_LEVEL_WEEK) {
    return WIDTH_WEEK_ALL_CONTENT;
  }

  const minWidthColumn = zoomLevelConfig[zoomLevel];
  return minWidthColumn;
};

/**
 * Retrieves the dimensions for the export PDF pages based on the Gantt chart and export format.
 *
 * @param {Object} params - The parameters object.
 * @param {Gantt} params.gantt - The Gantt chart instance.
 * @param {string} params.formatToExport - The format to export.
 * @param {boolean} params.isTrimParent - Flag that indicates whether the Trim parent activities option is active.
 * @returns {Object} - The dimensions of the page or an empty object.
 * @returns {number} [returns.width] - The width of the page in pixels.
 * @returns {number} [returns.height] - The height of the page in pixels.
 * @returns {string} [returns.format_text] - The format text, if all content is included.
 */
export const getPageDimensions = ({ gantt, formatToExport, isTrimParent }) => {
  if (!formatToExport) {
    return {};
  }

  const isAllContent = formatIsAllContent(formatToExport);
  if (isAllContent) {
    const height = calculateGanttTotalHeigth(gantt);
    const width = calculateGanttTotalWidth({
      gantt,
      isAllContent,
      isTrimParent
    });
    return {
      width,
      height,
      format_text: ALL_CONTENT
    };
  }

  const onPixelSize = formatOnPixels[formatToExport];
  return onPixelSize;
};

/**
 * Calculates the total number of pages required for exporting the Gantt chart as a PDF.
 *
 * @param {Object} params - The parameters object.
 * @param {Gantt} params.gantt - The Gantt chart instance.
 * @param {boolean} params.isFitOnePage - Flag indicating if the content should fit on one page.
 * @param {Object} params.pageDimensions - Dimensions of the page.
 * @param {number} params.pageDimensions.width - The width of the page in pixels.
 * @param {string} params.pageDimensions.format_text - The format text of the page.
 * @param {boolean} params.isTrimParent - Flag that indicates whether the Trim parent activities option is active.
 * @returns {number} - The total number of pages required.
 */
export const getTotalPages = ({
  gantt,
  isFitOnePage,
  pageDimensions,
  isTrimParent
}) => {
  const ONE_PAGE = 1;
  const { format_text: formatText, width } = pageDimensions;
  const isAllContent = formatIsAllContent(formatText);
  if (isFitOnePage || isAllContent) {
    return ONE_PAGE;
  }

  const totalWidth = calculateGanttTotalWidth({
    gantt,
    isAllContent,
    isTrimParent
  });
  return parseInt(totalWidth / width) + ONE_PAGE;
};

/**
 * Gets the width of the Gantt chart table element.
 *
 * @returns {number|null} - The width of the Gantt chart table in pixels, or `null` if the element is not found.
 */
export const getTableGanttWidth = () => {
  const TABLE_GANTT_WIDTH_CLASSNAME = 'gantt_grid_data';
  const DEFAULT_WIDTH = null;
  const INDEX = 0;

  const tableGanttWidth = document.getElementsByClassName(
    TABLE_GANTT_WIDTH_CLASSNAME
  )[INDEX];

  if (!tableGanttWidth) {
    return DEFAULT_WIDTH;
  }
  return tableGanttWidth.offsetWidth;
};
