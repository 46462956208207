import { getFormattedDateToP6XML } from '../../formateDate';
import { CURRENCY_DEFAULT } from './constants';
import { getProjectTask } from './projectProperties';

/**
 * Extracts and formats project data for P6 export.
 *
 * @param {Object} params - The parameters for extracting project data.
 * @param {Object} params.gantt - The gantt object containing the project task.
 * @param {Object} params.sector - The state of the project containing sector info.
 * @returns {Object} The project data formatted for P6 export.
 * Contains properties like projectId, currency, startDate, endDate, and name.
 */
export const getProjectToExportP6 = async ({ gantt, projectStateService }) => {
  const projectTask = getProjectTask(gantt);
  const { id, name } = await projectStateService.getSector();
  const { currency } = await projectStateService.getProject();

  return {
    projectId: id,
    currency: currency || CURRENCY_DEFAULT,
    startDate: getFormattedDateToP6XML(projectTask.start_date),
    endDate: getFormattedDateToP6XML(projectTask.end_date),
    name
  };
};
