import { store } from '../../../../redux/store';
import notificationSystemV2 from '../../../../components/DesignSystem/NotificationSystemV2';
import {
  setOrderFilter,
  setGeneralFilter,
  setRangeFilter
} from '../../../../views/ganttContainer/gantt/defaultView/filters';

function relaunchFilters(setOfActivitiesId, t, creatingActivty = false) {
  return new Promise((resolve) => {
    const gantt = window.to_use_react_gantt;
    if (!(gantt.isDataFiltered || gantt.isDataOrdered)) {
      return resolve();
    }

    const filterPromises = [];
    const activitiesToShow = new Set();
    const ganttState = store.getState().ganttState;
    const currentFilters = ganttState.showedFilters;
    const currentRange = ganttState.views?.range;
    const currentOrder = ganttState.views?.order;

    if (currentFilters.length) {
      filterPromises.push(
        setGeneralFilter(currentFilters, gantt, false).then(
          (showedActivities) => {
            showedActivities.forEach((activity) => {
              activitiesToShow.add(activity);
            });
          }
        )
      );
    }

    if (currentRange.start && currentRange.end) {
      filterPromises.push(
        setRangeFilter(currentRange, gantt).then((showedActivities) => {
          showedActivities.forEach((activity) => {
            activitiesToShow.add(activity);
          });
        })
      );
    }

    if (currentOrder?.length > 0) {
      setOrderFilter(currentOrder, gantt);
    }

    Promise.all(filterPromises).then(() => {
      const hasActivitiesToShow = activitiesToShow.size > 0;

      if (!hasActivitiesToShow) {
        return;
      }

      const isAtLeastOneOfTheCreatedActivitiesHidden = [
        ...setOfActivitiesId
      ].some((id) => !activitiesToShow.has(id));

      if (isAtLeastOneOfTheCreatedActivitiesHidden && creatingActivty) {
        notificationSystemV2({
          key: 'new_activity_hidden',
          message: t('new_activity_hidden'),
          type: 'warning'
        });
      }
      return resolve();
    });
  });
}

export { relaunchFilters };
