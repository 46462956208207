import { activityPdfService } from '../../../../../../services';
import { getGanttToExport } from '../../ExportPDFGantt/utils/gantt.utils';
import { createFileXml } from './createFileXML';
import { getResourcesForP6Export } from '../resources/index.js';

/**
 * Creates a P6 XML file for the project, triggers the file export, and handles the file download.
 * This involves fetching the project resources, creating the XML file, and triggering the download.
 *
 * @param {Object} params - The parameters for creating the P6 XML file.
 * @param {boolean} params.isSchedule - A flag indicating whether the XML file is for a schedule (true) or not (false).
 * @param {Object} params.projectState - The current state of the project, used to generate the P6 XML.
 * @returns {Promise<Object>} The generated P6 XML file object containing the XML string and its status.
 */
export const createP6XMLFile = async ({ isSchedule, projectState }) => {
  const newWindow = window.open('', '_blank');

  try {
    const gantt = getGanttToExport(isSchedule);

    const projectResources = await getResourcesForP6Export.execute({
      gantt,
      projectState
    });

    const p6XMLFile = await activityPdfService.exportP6XML({
      ...projectResources
    });

    const { p6XMLString, status } = p6XMLFile;
    if (!status) {
      return p6XMLFile;
    }

    createFileXml({ projectState, newWindow, p6XMLString });
    return p6XMLFile;
  } catch (error) {
    throw new Error(`Error in createP6XMLFile: ${error.message}`, {
      cause: error
    });
  } finally {
    newWindow.close();
  }
};
