import debounce from 'lodash/debounce';

import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';

import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';
import { trackingEvent } from '../../../../analytics';
import { getAllCheckedTasks, requestRender } from '../utils';
import { configureTaskHours } from './configureTaskHours';
import { modifyLagCustom } from './modifyLagCustom';
import { setAutoScheduling } from './setAutoScheduling';
import { setDuration } from './setDuration';
import { setConstraints } from './setConstraints';
import { sortByQuery } from './sortByQuery';
import { reloadDuration } from '../utils';
import { modificationForConstraint } from './modificationForConstraint.js';
import { resetMultiSelectTaskPosition } from './resetMultiSelectTaskPosition';
import { checkIfActivityViolateConstraint } from './checkIfActivityViolateConstraint';
import { TimerManagerSingleton } from '../../../../utils/timerManager.js';

const onAfterTaskDrag = debounce((id, t) => {
  const gantt = window.to_use_react_gantt;
  gantt.avoidAutoScheduleBeforeDrag = false;

  gantt.batchUpdate(() => {
    if (gantt.dragWithMultiple) {
      const allSelectedTasks = getAllCheckedTasks(true);
      const isSelected = allSelectedTasks.find((selected) => selected == id);
      const toExecuteTasks = !isSelected ? [id] : allSelectedTasks;
      resetMultiSelectTaskPosition(toExecuteTasks, t);
      allSelectedTasks.forEach((taskId) => {
        afterTaskDragUpdateProcess(taskId, gantt, t);
      });
    } else {
      afterTaskDragUpdateProcess(id, gantt, t);
    }
    gantt.isDragging = false;
    gantt.ext.undo.undoDisabled = true;
    gantt.ext.undo.undoDisabled = false;
    gantt.draggingMultipleALAP = false;
    if (gantt.draggingSingleMilestone) {
      gantt.maxPerformance = false;
      gantt.autoSchedule();
      gantt.draggingSingleMilestone = false;
    } else {
      const timerManager = TimerManagerSingleton.getInstance();
      const debouncedAfterDragCallback = () => {
        gantt.dragWithMultiple = false;
        gantt.draggingMultipleALAP = false;
        gantt.maxPerformance = false;
        gantt.autoSchedule();
      };
      timerManager.registerAutoTimeout(
        debouncedAfterDragCallback,
        500,
        'debouncedAfterDrag'
      );
    }
    gantt.resetLinksStateWithTimeout && gantt.resetLinksStateWithTimeout();
    requestRender();
  });
}, 100);

function afterTaskDragUpdateProcess(taskId = false, gantt, t) {
  gantt.is_task_moved = false;
  const task = gantt.getTask(taskId);
  configureTaskHours(task);
  const checkedTasks = getAllCheckedTasks(true).length;

  if (checkedTasks <= 1) {
    if (task.constraint_type != 'alap' && task.last_constraint != 'alap') {
      modifyLagCustom(task);
    }
  }

  task.real_constraint_type = task.constraint_type;
  task.correct_constraint_bug = true;
  gantt.editDatepicker = false;

  setAutoScheduling(task);
  setDuration(task);
  setConstraints(task);
  sortByQuery();

  delete task.modeDrag;

  if (!gantt.isRenderingOnProcess || !gantt.completingOnProcess) {
    reloadDuration(gantt);
  }

  if (checkedTasks <= 1) {
    modificationForConstraint(task, t);

    if (task.type !== 'milestone') {
      const timerManager = TimerManagerSingleton.getInstance();
      const afterTaskDragUpdateProcessCallback = () => {
        gantt.autoSchedule();
      };
      timerManager.registerAutoTimeout(
        afterTaskDragUpdateProcessCallback,
        500,
        'afterTaskDragUpdateProcess'
      );
    }
  } else {
    checkIfActivityViolateConstraint(task, true);
  }
  if (task.new_dates_drag) delete task.new_dates_drag;
  if (task.new_dates_before_drag) delete task.new_dates_before_drag;

  gantt.updateTask(taskId);

  gantt.isEditingDrag = false;
  trackingEvent(
    'activity_bar_drag&drop',
    {
      ...getBasicAmplitudEventProperties()
    },
    AMPLITUDE_SERVICE
  );
  task.dragged = false;
}

export { onAfterTaskDrag };
