import { sentryLogger } from '../../../monitor/monitor';
class ActivitiesStorage {
  static updatedActivities = new Map();
  static newActivities = new Map();
  static hasChanges = false;
  static initialized = false;
  static eventIds = [];
  static storageRef = null;

  static init(gantt) {
    if (this.initialized) return;

    if (!gantt?.getDatastore || !gantt.initAlreadyDid) return;

    const storage = gantt.getDatastore('task');
    this.storageRef = storage;
    this.initialized = true;

    const onStoreUpdatedId = storage.attachEvent(
      'onStoreUpdated',
      this._handleStoreUpdated.bind(this, gantt)
    );

    const onAfterAddId = storage.attachEvent(
      'onAfterAdd',
      this._handleAfterAdd.bind(this)
    );

    this.eventIds.push(onStoreUpdatedId, onAfterAddId);
  }

  static _handleStoreUpdated(gantt, id, item, action) {
    if (shouldAvoidUpdateStorage(action, item, gantt)) return;

    this.hasChanges = true;

    const parsedId = Number(id);
    if (this.newActivities.has(parsedId)) {
      this.newActivities.set(parsedId, { item });
      return;
    }

    this.updatedActivities.set(parsedId, { item });
  }

  static _handleAfterAdd(id, item) {
    const parsedId = Number(id);
    if (this.updatedActivities.has(parsedId)) {
      this.updatedActivities.delete(parsedId);
    }
    this.newActivities.set(parsedId, { item });
  }
  static removeEvents() {
    const storage = this.storageRef;
    if (!storage) return;

    this.eventIds.forEach((eventId) => {
      storage.detachEvent(eventId);
    });
    this.eventIds = [];
  }

  static reset() {
    this.updatedActivities.clear();
    this.newActivities.clear();
    this.hasChanges = false;
  }

  static kill() {
    try {
      this.reset();

      this.removeEvents();

      this.initialized = false;
    } catch (e) {
      sentryLogger('Error on ActivitiesStorage kill', e);
    } finally {
      this.initialized = false;
    }
  }

  static getUpdatedActivities() {
    return new Map(this.updatedActivities);
  }

  static getNewActivities() {
    return new Map(this.newActivities);
  }

  static storeDefaultData(defaultData = {}) {
    if (defaultData.data.length > 0) {
      defaultData.data.forEach((item) => {
        this.newActivities.set(item.id, { item });
      });
    }
  }

  static checkHasChanges() {
    return this.hasChanges;
  }
}

function shouldAvoidUpdateStorage(action, item, gantt) {
  const { initAlreadyDid, is_autoscheduling } = gantt;

  if (!action || action === 'paint') {
    return true;
  }

  if (item === false) {
    return true;
  }

  if (!initAlreadyDid || is_autoscheduling) {
    return true;
  }

  return false;
}

export { ActivitiesStorage };
